import { useState } from 'react'

import { useUser } from '../../../../../../hooks'
import { SettingsPanel } from '../../../SettingsPanel.component'
import { COMPANY_DETAILS_HEADING_ID } from '../../../../constants'
import { BillingInformationForm } from './BillingInformationForm.component'

export function BillingInformationFormPanel() {
  const { user } = useUser()
  const [billingInfoFormKey, setBillingInfoFormKey] = useState(Math.random())

  if (!user) {
    throw Error('No user')
  }

  return (
    <SettingsPanel
      id={COMPANY_DETAILS_HEADING_ID}
      className="border"
      title={<h2>Billing information</h2>}
      subtitle={
        <>
          <span className="mt-4 max-w-lg text-gray-600">
            These details are included on our invoices to you. If you have already set-up a company, please use your
            registered address
          </span>
        </>
      }
    >
      <BillingInformationForm key={billingInfoFormKey} onSuccess={() => setBillingInfoFormKey(Math.random())} />
    </SettingsPanel>
  )
}
