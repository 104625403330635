import { BrandedInsertLocationType } from '../../../hooks'

export function getInsertLocationLabel(insertLocation: BrandedInsertLocationType): string {
  switch (insertLocation) {
    case 'InPackage':
      return 'In the box'
    case 'OnPackage':
      return 'On the box'
    case 'OnProduct':
      return 'On the product'
    default:
      return insertLocation
  }
}
