import { Remark } from 'react-remark'
import React, { useState } from 'react'

import { FooterContainer } from '.'
import { removeDuplicates } from '../../../helpers'
import { ProductCatalogueProductInterface } from '../../../hooks'
import ToggleGroup, { ToggleGroupItem } from '../../ToggleGroup'

type ClothingUnitType = 'uk' | 'eu' | 'us'
type SizeUnitType = 'mm' | 'in'

export function SizeInformation({ productData }: { productData: ProductCatalogueProductInterface }) {
  const labels = removeDuplicates(
    Object.values(productData.sizes.data)
      .map((details) => details.map((option) => option.label))
      .flat()
  )

  // Default values copied from marketing site
  const [clothingUnit, setClothingUnit] = useState<ClothingUnitType>('uk')
  const [sizeUnit, setSizeUnit] = useState<SizeUnitType>('mm')

  const colWidth = (1 / (labels.length + 2)) * 100
  const hasMMValues = Object.values(productData.sizes.data).some((details) =>
    details.some((option) => Boolean(option.valueInMM))
  )
  const sizingRegex = new RegExp(/^UK [0-9]+$/)
  const hasShoeSizes = Object.values(productData.sizes.data).some((details) =>
    details.some((option) => sizingRegex.test(option.label) || ['UK womens', 'UK shoe'].includes(option.sizeUnit))
  )

  return (
    <FooterContainer>
      <div className="overflow-x-auto">
        <table className="table--stacked table--stacked--inline">
          <thead>
            <tr>
              <th style={{ width: `${2 * colWidth}%` }}></th>
              {labels.map((label) => (
                <th className="text-left" key={label} style={{ width: `${colWidth}%` }}>
                  <span>{label}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(productData.sizes.data).map(([label, sizes]) => (
              <tr key={label}>
                <td>{label}</td>
                {labels.map((sizeLabel) => {
                  const availableDetails = sizes.find((size) => size.label === sizeLabel)

                  if (!availableDetails) {
                    return (
                      <td className="py-6" key={label + sizeLabel}>
                        -
                      </td>
                    )
                  } else {
                    return (
                      <td className="py-6" data-label={availableDetails.label} key={availableDetails.label}>
                        {availableDetails.valueInMM && sizeUnit === 'mm' ? (
                          <>
                            <span>{availableDetails.valueInMM}</span>
                            <span>{sizeUnit}</span>
                          </>
                        ) : ['UK womens', 'UK shoe'].includes(availableDetails.sizeUnit) ? (
                          <span>{formatSize(clothingUnit, availableDetails.sizeUnit, availableDetails.value)}</span>
                        ) : (
                          <>
                            <span>
                              {sizeUnit === 'in' && availableDetails.valueInMM
                                ? formatSizeInInches(availableDetails.valueInMM)
                                : availableDetails.value}
                            </span>
                            <span>
                              {sizeUnit === 'in' && availableDetails.valueInMM ? sizeUnit : availableDetails.sizeUnit}
                            </span>
                          </>
                        )}
                      </td>
                    )
                  }
                })}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="text-right" colSpan={labels.length + 1}>
                {hasMMValues && (
                  <ToggleGroup
                    className="float-left mb-2 flex max-w-fit active:flex lg:float-right lg:ml-4"
                    type="single"
                    value={sizeUnit}
                    variant="monochrome"
                    onValueChange={(value: string) => {
                      if (['mm', 'in'].includes(value)) {
                        setSizeUnit(value as SizeUnitType)
                      }
                    }}
                  >
                    <ToggleGroupItem value="mm" aria-label="mm">
                      <span>mm</span>
                    </ToggleGroupItem>
                    <ToggleGroupItem value="in" aria-label="in">
                      <span>in</span>
                    </ToggleGroupItem>
                  </ToggleGroup>
                )}
                {hasShoeSizes && (
                  <ToggleGroup
                    className="float-right max-w-fit"
                    type="single"
                    value={clothingUnit}
                    variant="monochrome"
                    onValueChange={(value: string) => {
                      if (['uk', 'eu', 'us'].includes(value)) {
                        setClothingUnit(value as ClothingUnitType)
                      }
                    }}
                  >
                    <ToggleGroupItem value="uk" aria-label="uk">
                      <span>UK</span>
                    </ToggleGroupItem>
                    <ToggleGroupItem value="eu" aria-label="eu">
                      <span>EU</span>
                    </ToggleGroupItem>
                    <ToggleGroupItem value="us" aria-label="us">
                      <span>US</span>
                    </ToggleGroupItem>
                  </ToggleGroup>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
        {productData.sizes?.notes && (
          <div className="mt-6 text-sm text-gray-600">
            <Remark
              rehypeReactOptions={{
                components: {
                  p(props: JSX.IntrinsicAttributes) {
                    return <React.Fragment {...props} />
                  },
                  a(props: JSX.IntrinsicAttributes) {
                    return <a {...props} className="w-fit text-sm text-gray-600" target="_blank" rel="noreferrer" />
                  }
                }
              }}
            >
              {productData.sizes?.notes}
            </Remark>
          </div>
        )}
      </div>
    </FooterContainer>
  )
}

// copied from the marketing site
function roundTo025(value: number) {
  return (Math.round(value * 4) / 4).toFixed(2)
}

function formatSizeInInches(valueInMM: string) {
  const splitSize = valueInMM.split('–')
  const formatted: number[] = []

  splitSize.forEach((unitPart) => {
    formatted.push(+roundTo025(Number(unitPart) / 25.4))
  })

  return formatted.join('-')
}

// logic copied from marketing site => https://github.com/Prodigi-Group/www.prodigi.com/blob/develop/source/javascript/site/user.js#L174
function formatSize(selectedUnit: string, unit: string, value: string) {
  const isShoe = unit.replace('UK', '') === 'shoe'
  const splitSize = value.split('–')
  const formatted: number[] = []

  splitSize.forEach((unitPart) => {
    const unitvalue = parseInt(unitPart)

    if (selectedUnit === 'us') {
      isShoe ? formatted.push(unitvalue + 2) : formatted.push(Math.max(1, unitvalue - 4))
    } else if (selectedUnit === 'eu') {
      isShoe ? formatted.push(unitvalue + 33) : formatted.push(unitvalue + 28)
    } else {
      formatted.push(unitvalue)
    }
  })

  return formatted.join('-')
}
