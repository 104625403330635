// @flow

import { buildImageId } from '../images'
import { values } from '../../helpers/dictionary'
import { removeCsvItemArtwork } from './removeCsvItemArtwork'
import { getArtworkByItemId } from '../../selectors/images/images'
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'
import { addDummyImagesForNonImageProducts } from './addDummyImagesForNonImageProducts'
import { fetchPricingAndShippingForCsvOrder } from './fetchPricingAndShippingForCsvOrder'
import { getCsvOrderByOrderItemId, getCsvOrderItems, getProductBySku } from '../../selectors/csvUpload/csvUpload'

// $FlowFixMe: TypeScript file
import { getProductFileCompatibilityData } from 'src/v2/helpers'

export function filterUnsupportedFileTypesForItems(): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const orderItems = getCsvOrderItems(getState())
    const orderIdsToRefetchQuotes: string[] = []

    values(orderItems).forEach((orderItem) => {
      const catalogueDetails = getProductBySku(getState(), orderItem.sku)
      const productFileCompatibilityData = getProductFileCompatibilityData({
        productType: catalogueDetails?.productType
      })

      Object.keys(orderItem.printAreaImageUrls ?? {}).forEach((printAreaName) => {
        const imageId = buildImageId(orderItem.id, printAreaName)
        const artwork = getArtworkByItemId(getState(), imageId)
        const order = getCsvOrderByOrderItemId(getState(), orderItem.id)

        if (artwork?.pageCount && artwork?.pageCount > 0 && order) {
          orderIdsToRefetchQuotes.push(order?.id)
        }

        const isImageFileType = artwork?.fileType === 'png' || artwork?.fileType === 'jpg'
        const isArtworkSupportedForItem = productFileCompatibilityData
          ? productFileCompatibilityData.fileTypesAllowed.includes(artwork?.fileType)
          : isImageFileType

        if (!catalogueDetails || !isArtworkSupportedForItem) {
          dispatch(removeCsvItemArtwork(orderItem.id, printAreaName))
        }
      })
    })

    dispatch(addDummyImagesForNonImageProducts(orderItems))

    orderIdsToRefetchQuotes.forEach((orderId) => {
      dispatch(fetchPricingAndShippingForCsvOrder(orderId))
    })
  }
}
