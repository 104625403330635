import RadioGroup from '../../RadioGroup'
import { ProBillingOption } from '../interfaces'
import { calculatePlanSaving, formatBillingCost } from '../helpers'
import { RadioGroupItemInterface } from '../../RadioGroup/RadioGroup.component'

export function ProPlanBillingRadioButtons({
  proPlanBillingOptions,
  selectedSubscriptionTier,
  setSelectedSubscriptionTier
}: {
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedSubscriptionTier: ProBillingOption
  setSelectedSubscriptionTier: (value: React.SetStateAction<ProBillingOption>) => void
}) {
  const saving = calculatePlanSaving(proPlanBillingOptions)

  return (
    <RadioGroup
      autoFocus
      className="mt-4 max-w-3xl"
      ariaLabel="Payment plan"
      selectedValue={selectedSubscriptionTier}
      onChange={(value) => {
        setSelectedSubscriptionTier(value as ProBillingOption)
      }}
      radioGroupItemClassName="items-start"
      items={Object.entries(proPlanBillingOptions).map(([id, cost]) => ({
        id,
        label: formatBillingCost(cost),
        value: id
      }))}
      renderLabel={(item) => (
        <label htmlFor={item.value} className="-mt-1 flex cursor-pointer flex-col text-left">
          <span>{formatLabel(item, saving)}</span>
        </label>
      )}
    />
  )
}

function formatLabel(item: RadioGroupItemInterface, saving: string) {
  if (item.value === 'ProMonthlyBilling') {
    return <span>Pay monthly: {item.label} per month</span>
  }

  return (
    <span>
      Pay annually: {item.label} per year <span className="text-red-800">SAVE {saving}</span>
    </span>
  )
}
