// @flow
import type { FormattedOrderItem, DispatchFunc, GetStateFunc, Thunk } from '../../types'
import { getSelectedOrderItemIds, getCsvOrderByOrderItemId, getCsvOrderItemsByItemIds } from '../../selectors/csvUpload'
import { onChangeProductDetails } from './onChangeProductDetails'
import { filterUnsupportedFileTypesForItems } from './filterUnsupportedFileTypesForItems'

export const COPY_PRODUCT_DETAILS = 'COPY_PRODUCT_DETAILS'

export function copyProductDetails(orderItem: FormattedOrderItem): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const idsOfItemsToUpdate = getSelectedOrderItemIds(getState())
    const orderItemsBeforeUpdate = getCsvOrderItemsByItemIds(getState(), idsOfItemsToUpdate)
    const { sku, selectedAttributes, metaData } = orderItem

    dispatch({
      sku,
      selectedAttributes,
      metaData,
      idsOfItemsToUpdate,
      type: COPY_PRODUCT_DETAILS
    })

    idsOfItemsToUpdate.forEach((idOfUpdatedOrderItem) => {
      const orderItemBeforeUpdate = orderItemsBeforeUpdate[idOfUpdatedOrderItem]

      const updatedOrder = getCsvOrderByOrderItemId(getState(), orderItemBeforeUpdate.id)

      if (updatedOrder) {
        dispatch(onChangeProductDetails(updatedOrder, orderItemBeforeUpdate, sku, selectedAttributes))
      }
    })

    dispatch(filterUnsupportedFileTypesForItems())
  }
}
