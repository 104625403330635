// @flow
import type { FormattedOrderItem, DispatchFunc, GetStateFunc, Artwork, Thunk, ThunkAsync } from '../../types'
import type { UpdateArtworkTransformations } from '../types'
import { getArtworkByItemId, getArtworkTransformationsById } from '../../selectors/images'
import { getCsvItemTemplates } from '../../selectors/csvUpload'
import { createListOfArtworkAndTransformationIds, createListOfArtworkIds } from '../../helpers/createListOfIds'
import { updateCsvItemUrl } from './uploadArtwork'
import { initArtworkTransformations, copyArtworkDetails, UPDATE_ARTWORK_TRANSFORMATIONS, buildImageId } from '../images'
import { entries } from '../../helpers/dictionary'
import { filterUnsupportedFileTypesForItems } from './filterUnsupportedFileTypesForItems'

export function copyImageDetails(orderItem: FormattedOrderItem): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const { sku, selectedAttributes } = orderItem
    const selectedItemId = orderItem.id

    const artworksByPrintAreaName = Object.keys(orderItem.printAreaImageUrls).reduce((artworkAcc, printAreaName) => {
      const imageId = buildImageId(selectedItemId, printAreaName)
      const artwork = getArtworkByItemId(getState(), imageId)
      if (artwork) {
        artworkAcc[printAreaName] = artwork
      }

      return artworkAcc
    }, {})

    const idsOfItemsRequiringImages = createListOfArtworkIds(getState(), selectedItemId)
    const idsOfArtworkAndTransformationsToUpdate = createListOfArtworkAndTransformationIds(
      getState(),
      sku,
      selectedAttributes,
      selectedItemId
    )

    idsOfItemsRequiringImages.forEach((itemId, index) => {
      const hasTransformationToUpdate = idsOfArtworkAndTransformationsToUpdate.includes(itemId)

      entries(artworksByPrintAreaName).forEach(([printAreaName, artwork]) => {
        if (hasTransformationToUpdate) {
          dispatch(
            updateArtwork(
              itemId,
              artwork,
              selectedItemId,
              artwork.croppedImageUrl,
              printAreaName,
              artwork.transformImageUrl ?? artwork.croppedImageUrl
            )
          )
        } else {
          dispatch(updateTransformation(itemId, orderItem.sku, artwork, printAreaName))
        }
      })
    })

    dispatch(filterUnsupportedFileTypesForItems())
  }
}

function updateTransformation(
  itemId: string,
  sku: string,
  artwork: ?Artwork,
  selectedPrintArea: string
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const templates = getCsvItemTemplates(getState(), itemId)
    const imageId = buildImageId(itemId, selectedPrintArea)

    if (templates && artwork) {
      dispatch(copyArtworkDetails(itemId, artwork, selectedPrintArea))
      dispatch(initArtworkTransformations({ artwork, templates, itemId: imageId, printAreaName: selectedPrintArea }))
      dispatch(updateCsvItemUrl(artwork.originalImageUrl, itemId, selectedPrintArea, artwork.originalImageUrl))
    }
  }
}

function updateArtwork(
  itemId: string,
  artwork: ?Artwork,
  selectedItemId: string,
  url: string,
  selectedPrintArea: string,
  transformImageUrl: string
) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const selectedImageId = buildImageId(selectedItemId, selectedPrintArea)
    const transformations = getArtworkTransformationsById(getState(), selectedImageId)

    if (artwork) {
      await dispatch(copyArtworkDetails(itemId, artwork, selectedPrintArea))
    }

    const imageId = buildImageId(itemId, selectedPrintArea)

    if (transformations) {
      const updateTransformations: UpdateArtworkTransformations = {
        type: UPDATE_ARTWORK_TRANSFORMATIONS,
        id: imageId,
        transformations
      }
      await dispatch(updateTransformations)
    }

    dispatch(updateCsvItemUrl(url, itemId, selectedPrintArea, transformImageUrl))
  }
}
