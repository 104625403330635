import { ProBillingOption } from '../interfaces'
import { formatBillingCost } from './formatBillingCost.helper'

export function calculatePlanSaving(
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
) {
  const monthlyCostForYear = proPlanBillingOptions.ProMonthlyBilling.cost * 12
  const saving = monthlyCostForYear - proPlanBillingOptions.ProYearlyBilling.cost

  return formatBillingCost({
    cost: saving,
    currency: proPlanBillingOptions.ProYearlyBilling.currency
  })
}
