import toast from 'react-hot-toast'

import Button from '../../Button'
import Skeleton from '../../Skeleton'
import { createToast } from '../../Toast'
import { Panel } from './Panel.component'
import { StatusEnum } from '../../../enums'
import { cn, fetcher } from '../../../helpers'
import { useMerchantService } from '../../../hooks'
import timeFormat from '../../../helpers/timeFormat'
import { useRutterProdxySalesChannelDetails } from '../hooks'
import { FetchErrorInterface, SalesChannelInterface } from '../../../interfaces'
import { CHANNEL_CONFIGURE_TOAST_IDS } from '../../SalesChannelConfigure/constants'

interface SalesChannelResyncSettingPropsInterface {
  isLoading: boolean
  salesChannel: SalesChannelInterface
  setResyncChannelStatus: (status: StatusEnum) => void
}

export function SalesChannelResyncSetting({
  isLoading,
  salesChannel,
  setResyncChannelStatus
}: SalesChannelResyncSettingPropsInterface) {
  const { merchantDetails } = useMerchantService()
  const {
    channelDetails,
    isLoading: isLoadingDetails,
    error,
    mutate: mutateRutterProxyDetails
  } = useRutterProdxySalesChannelDetails(salesChannel.id)

  async function handleResync() {
    toast.dismiss()
    setResyncChannelStatus(StatusEnum.Loading)

    try {
      if (!channelDetails || !merchantDetails) {
        throw Error('No details')
      }

      const newProxyDetails = {
        ...channelDetails,
        canResync: false
      }

      await fetcher(
        `${process.env.REACT_APP_RUTTER_PROXY}/merchantId/${merchantDetails?.id}/saleschannel/${salesChannel.id}/resync`,
        {
          method: 'POST',
          body: JSON.stringify({
            defaultFulfillment: salesChannel.defaultFulfillment
          })
        }
      )

      setResyncChannelStatus(StatusEnum.Success)
      mutateRutterProxyDetails(newProxyDetails)
      createToast({
        heading: 'Sales channel resync has begun successfully',
        id: CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS,
        type: 'success'
      })
      window.analytics.track('Sales Channel Resync')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to resync',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setResyncChannelStatus(StatusEnum.Error)
    }
  }

  if (error) {
    return (
      <ResyncPanel>
        <p className="w-max-full pt-4 text-red-500">An unexpected error occured. Code {error?.status ?? '0'}</p>
      </ResyncPanel>
    )
  }

  if (isLoadingDetails || !channelDetails) {
    return (
      <ResyncPanel>
        <Skeleton className="h-[43px] w-[130px]" />
      </ResyncPanel>
    )
  }

  if (!channelDetails.isActive) {
    return (
      <ResyncPanel>
        <Button disabled variant="primary" onClick={() => handleResync()}>
          Update now
        </Button>
        <div className="mt-2 text-xs">Channel inactive</div>
      </ResyncPanel>
    )
  }

  if (isLoading || channelDetails.isSyncing) {
    return (
      <ResyncPanel>
        <Button isLoading={true} variant="secondary" onClick={() => handleResync()}>
          In progress...
        </Button>
        <Footer canSync={channelDetails.canResync} lastPollDateTime={channelDetails.lastPollDateTime} />
      </ResyncPanel>
    )
  }

  if (!channelDetails.canResync) {
    return (
      <ResyncPanel>
        <Button disabled variant="primary" onClick={() => handleResync()}>
          Update now
        </Button>
        <Footer canSync={channelDetails.canResync} lastPollDateTime={channelDetails.lastPollDateTime} />
      </ResyncPanel>
    )
  }

  return (
    <ResyncPanel>
      <Button variant="secondary" onClick={() => handleResync()}>
        Update now
      </Button>
      <Footer canSync={channelDetails.canResync} lastPollDateTime={channelDetails.lastPollDateTime} />
    </ResyncPanel>
  )
}

function ResyncPanel({ children }: { children: React.ReactNode }) {
  return (
    <Panel
      className="border-t px-0"
      id="channel-resync"
      title={<h3 className="mt-0">Update store data</h3>}
      subtitle={
        <span className="mt-4 max-w-lg text-base text-gray-600">
          Products & orders are synchronised automatically. If changes are delayed, you can refresh manually here.
        </span>
      }
    >
      {children}
    </Panel>
  )
}

function Footer({ canSync, lastPollDateTime }: { canSync: boolean; lastPollDateTime: string }) {
  return (
    <>
      <p className="w-max-full pt-4 text-sm text-gray-600">
        Last synced {timeFormat(lastPollDateTime)} on {dateFormat(lastPollDateTime)}
      </p>
      <p className={cn('w-max-full pt-4 text-sm text-red-800', canSync ? 'opacity-0' : 'opacity-100')}>
        Manual updates are limited to once per hour.
      </p>
    </>
  )
}

function dateFormat(datetime: string) {
  return new Date(datetime).toLocaleDateString('en-gb', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}
