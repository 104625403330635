import { useState } from 'react'

import {
  formatCost,
  formatToSentenceCase,
  isBook,
  productAttributeFormat,
  removeDuplicates,
  splitByPascalCase
} from '../../../helpers'
import Skeleton from '../../Skeleton'
import { buildQuote } from '../helpers'
import { ProductDetailInterface, useQuotes } from '../../../hooks'
import { ProductDataInterface, QuoteInterface } from '../../../interfaces'
import { CountryPickerModalButton } from './CountryPickerModalButton.component'

const SHIPPING_METHODS = ['Budget', 'Standard', 'Express', 'Overnight']

export function PricingAndShipping({
  countryCode,
  currencyCode,
  product,
  productDetails,
  showCountryPicker
}: {
  countryCode: string
  currencyCode: string
  // TODO: Make required prop when removing split COSTED_ATTRIBUTES: 'dashboard_costed_attributes'
  product?: ProductDataInterface
  productDetails: ProductDetailInterface
  showCountryPicker: boolean
}) {
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(countryCode)
  const quoteItem = buildQuote({ productDetails })
  const {
    quotesData,
    isLoading: isLoadingQuotes,
    error: quotesFetchError
  } = useQuotes({
    config: { revalidateOnFocus: false },
    countryCode: selectedCountryCode,
    currencyCode,
    items: quoteItem
  })

  const costedAttributes = product?.pwintyProduct?.costedAttributes ?? {}

  if (quotesFetchError) {
    return (
      <>
        <h2 className="mt-0 text-lg md:mt-8 lg:mt-0 lg:font-normal">Pricing & shipping</h2>
        <span className="flex flex-col gap-2">
          An error occured while fetching pricing and shipping information, please try again later
        </span>
      </>
    )
  }

  return (
    <>
      <h2 className="mt-0 text-lg md:mt-8 lg:mt-0 lg:font-normal">
        <span className="flex flex-col">
          <span>
            Pricing & shipping <span className="md:hidden">to {selectedCountryCode}</span>
          </span>
          {showCountryPicker && <CountryPickerModalButton className="md:hidden" onSelection={setSelectedCountryCode} />}
        </span>
      </h2>
      <div className="flex min-w-full flex-col gap-2">
        <table className="mb-4 mr-4 max-w-5xl">
          <thead className="hidden md:table-header-group md:border-b">
            <tr>
              <th scope="col" className="pb-4">
                <div className="content-start">
                  <div className="flex items-center">
                    <span>Shipping to {selectedCountryCode} </span>
                    <img
                      className="ml-1 inline-block"
                      key={selectedCountryCode}
                      src={`/img/flags-100/${selectedCountryCode}.png`}
                      title={selectedCountryCode}
                      width="28"
                    />
                  </div>
                  {showCountryPicker && <CountryPickerModalButton onSelection={setSelectedCountryCode} />}
                </div>
              </th>
              <th scope="col" className="pb-6 pl-12 text-right">
                From
              </th>
              <th scope="col" className="w-44 pb-6 text-right">
                Item <span className="block text-sm text-gray-600">+ tax</span>
              </th>
              <th scope="col" className="w-44 pb-6 text-right">
                Shipping <span className="block text-sm text-gray-600">+ tax</span>
              </th>
              <th scope="col" className="w-44 pb-6 text-right">
                Total <span className="block text-sm text-gray-600">+ tax</span>
              </th>
            </tr>
          </thead>
          <tbody className="block space-y-8 md:table-row-group md:border-b">
            {SHIPPING_METHODS.map((method) => {
              if (isLoadingQuotes) {
                return <LoadingQuotesState key={method} shipmentMethod={method} />
              }

              const quote = quotesData?.quotes.find(
                (quote) => quote.shipmentMethod.toLowerCase() === method.toLowerCase()
              )

              return quote ? (
                <PricingQuote key={quote.shipmentMethod} quote={quote} />
              ) : (
                <MissingPricingQuote key={method} shipmentMethod={method} />
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5} className="text-sm text-gray-600">
                <p className="pt-8 md:pt-4">
                  Shipping prices are based on a single item
                  {Object.keys(quoteItem?.[0]?.attributes ?? {}).length > 0 && (
                    <>
                      {' '}
                      (
                      {Object.entries(quoteItem?.[0]?.attributes ?? {})
                        .map(([attributeName, attributeValue]) => {
                          const costedAttributeData = costedAttributes[attributeName]
                          const costedAttributeOption = costedAttributeData?.options?.find(
                            (option) => option?.value === attributeValue
                          )
                          if (costedAttributeData && costedAttributeOption) {
                            return `${costedAttributeData.name}: ${costedAttributeOption.name}`
                          }

                          return `${formatToSentenceCase(splitByPascalCase(attributeName))}: ${productAttributeFormat(
                            attributeValue
                          )}`
                        })
                        .join(', ')}
                      )
                    </>
                  )}
                  ; multiple items{isBook(productDetails.sku) ? ' or page count ' : ' '}
                  may increase the amount charged. Fulfilment location (and in turn item price) may vary at the point of
                  ordering, particularly with mixed-product orders.
                </p>
                {selectedCountryCode === 'US' && (
                  <p className="pt-4">US tax charges are state-specific and will be calculated at checkout.</p>
                )}
              </td>
            </tr>
            <ShippingTaxInformation countryCode={selectedCountryCode} quotes={quotesData?.quotes} />
          </tfoot>
        </table>
      </div>
    </>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */
function TableCell({ className, content, heading }: { className: string; content: JSX.Element; heading?: string }) {
  return (
    <td className="block py-1 md:table-cell md:py-2">
      <div className={`md:flex md:flex-col md:pb-8 ${className}`}>
        <span className="md:hidden">
          <strong>{heading}</strong>
        </span>
        {content}
      </div>
    </td>
  )
}

enum TableCellHeadings {
  From = 'From: ',
  Item = 'Item + tax: ',
  Shipping = 'Shipping + tax: ',
  ShippingTo = 'Service: ',
  Total = 'Total + tax: '
}

function LoadingQuotesState({ shipmentMethod }: { shipmentMethod: string }) {
  return (
    <tr className="block md:table-row">
      <TableCell className="md:pt-4" content={<span>{shipmentMethod}</span>} heading={TableCellHeadings.ShippingTo} />
      <TableCell
        className="items-end md:h-[40px] md:pb-8 md:text-right"
        content={<Skeleton className="w-12" />}
        heading={TableCellHeadings.From}
      />
      <TableCell
        className="items-end md:h-[40px] md:pb-8 md:text-right"
        content={
          <div className="flex flex-col md:items-end">
            <Skeleton className="w-24" />
            <Skeleton className="mt-2 w-16" />
          </div>
        }
        heading={TableCellHeadings.Item}
      />
      <TableCell
        className="items-end md:h-[40px] md:pb-8 md:text-right"
        content={
          <div className="flex flex-col md:items-end">
            <Skeleton className="w-24" />
            <Skeleton className="mt-2 w-16" />
          </div>
        }
        heading={TableCellHeadings.Shipping}
      />
      <TableCell
        className="md:h-[40px md:pb-8] items-end md:text-right"
        content={
          <div className="flex flex-col md:items-end">
            <Skeleton className="w-24" />
            <Skeleton className="mt-2 w-16" />
          </div>
        }
        heading={TableCellHeadings.Total}
      />
    </tr>
  )
}

function MissingPricingQuote({ shipmentMethod }: { shipmentMethod: string }) {
  return (
    <tr className="block md:table-row">
      <TableCell className="md:pt-4" content={<span>{shipmentMethod}</span>} heading={TableCellHeadings.ShippingTo} />
      <td colSpan={4} className="font-light italic text-gray-600 md:pt-4 md:text-center">
        Unavailable
      </td>
    </tr>
  )
}

function PricingQuote({ quote }: { quote: QuoteInterface }) {
  const shipment = quote.shipments[0]
  const item = quote.items[0]
  const totalCostExcludingTax = Number(quote.costSummary.totalCost.amount) - Number(quote.costSummary.totalTax.amount)

  return (
    <tr className="block md:table-row">
      <TableCell
        className="md:pt-4"
        content={<span>{quote.shipmentMethod}</span>}
        heading={TableCellHeadings.ShippingTo}
      />
      <TableCell
        className="md:text-right"
        content={<span>{shipment.fulfillmentLocation.countryCode}</span>}
        heading={TableCellHeadings.From}
      />
      <TableCell
        className="md:text-right"
        content={
          <>
            {formatCost({
              amount: item.unitCost.amount,
              currencyCode: item.unitCost.currency
            })}{' '}
            <span className="text-sm text-gray-600">
              + {formatCost({ amount: item.taxUnitCost.amount, currencyCode: item.taxUnitCost.currency })}
            </span>
          </>
        }
        heading={TableCellHeadings.Item}
      />
      <TableCell
        className="md:text-right"
        content={
          <>
            {formatCost({
              amount: shipment.cost.amount,
              currencyCode: shipment.cost.currency
            })}{' '}
            <span className="text-sm text-gray-600">
              + {formatCost({ amount: shipment.tax.amount, currencyCode: shipment.tax.currency })}
            </span>
          </>
        }
        heading={TableCellHeadings.Shipping}
      />
      <TableCell
        className="md:text-right"
        content={
          <>
            {formatCost({
              amount: totalCostExcludingTax.toString(),
              currencyCode: quote.costSummary.totalCost.currency
            })}{' '}
            <span className="text-sm text-gray-600">
              +{' '}
              {formatCost({
                amount: quote.costSummary.totalTax.amount,
                currencyCode: quote.costSummary.totalTax.currency
              })}
            </span>
          </>
        }
        heading={TableCellHeadings.Total}
      />
    </tr>
  )
}

function ShippingTaxInformation({
  countryCode,
  quotes
}: {
  countryCode: string
  quotes: QuoteInterface[] | undefined
}) {
  const locations = removeDuplicates(
    SHIPPING_METHODS.reduce((locationsAcc: string[], method) => {
      const quote = quotes?.find((quote) => method.toLowerCase() === quote.shipmentMethod.toLowerCase())
      const country = quote?.shipments[0]?.fulfillmentLocation.countryCode

      if (country && country.toLowerCase() !== countryCode.toLowerCase()) {
        locationsAcc.push(country)
      }

      return locationsAcc
    }, [])
  )

  if (locations.length === 0) {
    return null
  }

  const numberOfLocations = locations.length
  const locationsAsString = numberOfLocations === 1 ? locations[0] : mapLocations(locations)

  return (
    <tr>
      <td colSpan={5} className="text-sm text-gray-600">
        <p className="pt-8 md:pt-4">
          This product will ship from {locationsAsString}. The recipient may be charged a customs fee.
        </p>
      </td>
    </tr>
  )
}

function mapLocations(locations: string[]) {
  const lastLocation = locations.pop()
  return locations.join(', ') + ' or ' + lastLocation
}
