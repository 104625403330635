import styles from './CsvItemProductView.module.css'
import { PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS } from '../../../../v2/constants'

export function CsvItemMetaDataView({
  metaData
}: {
  metaData?: {
    spineText?: string
    spineBackgroundColourHexCode?: string
    spineTextColourHexCode?: string
    spineTextSizePx?: number
    spineTextFontFamily?: string
  }
}) {
  return (
    <>
      {Boolean(metaData?.spineText) && (
        <>
          <span className={styles.attribute}>
            <span>Spine Text:</span> <span style={{ wordBreak: 'break-word' }}>{metaData?.spineText}</span>
          </span>
        </>
      )}

      {Boolean(metaData?.spineTextSizePx) && (
        <>
          <span className={styles.attribute}>
            <span>Spine Text Size:</span> <span>{metaData?.spineTextSizePx}</span>
          </span>
        </>
      )}

      {Boolean(metaData?.spineTextFontFamily) && metaData?.spineTextFontFamily && (
        <>
          <span className={styles.attribute}>
            <span>Spine Text Font:</span>{' '}
            <span>
              <SpineTextFont selectedValue={metaData.spineTextFontFamily} />
            </span>
          </span>
        </>
      )}

      {Boolean(metaData?.spineBackgroundColourHexCode) && (
        <>
          <span className={styles.attribute} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <span>Spine Background colour:</span>{' '}
            <span>
              <div className="tailwind" style={{ display: 'inline-block' }}>
                <div
                  className="inline-block h-7 w-7 border"
                  style={{ backgroundColor: metaData?.spineBackgroundColourHexCode }}
                ></div>
              </div>
            </span>
          </span>
        </>
      )}

      {Boolean(metaData?.spineTextColourHexCode) && (
        <>
          <span className={styles.attribute} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <span>Spine Text colour:</span>{' '}
            <span>
              <span className="tailwind">
                <div className="h-7 w-7 border" style={{ backgroundColor: metaData?.spineTextColourHexCode }}></div>
              </span>
            </span>
          </span>
        </>
      )}
    </>
  )
}

function SpineTextFont({ selectedValue }: { selectedValue: string }) {
  const selectedFontOption = PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS.find(
    (fontOption) => fontOption.value === selectedValue
  )

  return <>{selectedFontOption?.name ?? selectedValue}</>
}
