import * as React from 'react'
import * as TogglePrimitive from '@radix-ui/react-toggle'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../helpers'

const toggleVariants = cva(
  'inline-flex first:rounded-l-full self-stretch last:rounded-r-full items-center justify-center transition-colors duration-300 disabled:pointer-events-none disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-purple-500',
  {
    variants: {
      variant: {
        default:
          'data-[state=on]:bg-purple-600 data-[state=on]:text-white data-[state=off]:bg-purple-100 data-[state=off]:hover:bg-purple-200 flex w-full bg-white text-black hover:bg-purple-200 border border-purple-600',
        monochrome:
          'data-[state=on]:bg-gray-300 flex w-full bg-white text-black hover:bg-gray-400 border border-gray-300 data-[state=off]:hover:border-gray-400'
      },
      size: {
        default: 'p-3 text-base',
        sm: 'py-1 px-3 text-sm'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
  // eslint-disable-next-line react/prop-types
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root ref={ref} className={cn(toggleVariants({ variant, size, className }))} {...props} />
))

Toggle.displayName = TogglePrimitive.Root.displayName

export { Toggle, toggleVariants }
