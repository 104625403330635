// @flow
export const FEATURE_NAMES = {
  TRANSORM_URLS: 'dashboard_transform_urls',
  DASHBOARD_SHOPIFY_REVIEW: 'dashboard_shopify_review',
  SALES_CHANNELS_DELETE_ORDERS: 'sales_channels_delete_orders',
  IMAGE_LIBRARY_PDF: 'AllowPDFUploadsToImageLibrary',
  DASHBOARD_APC: 'dashboard_apc',
  DASHBOARD_APC_SEARCH: 'dashboard_apc_search',
  COSTED_ATTRIBUTES: 'dashboard_costed_attributes',
  SALES_CHANNEL_VOTE_FOR_PLATFORM: 'sales_channel_vote_for_platform',
  DASHBOARD_3D_MOCKUPS: 'dashboard_3d_mockups',
  PHOTOBOOK_METADATA: 'photobook_metadata',
  BRANDING_SETTINGS: 'dashboard_branding_settings',
  DASHBOARD_SALES_CHANNEL_RESYNC: 'dashboard_sales_channel_resync',
  DASHBOARD_PRODIGI_PRO: 'dashboard_prodigi_pro',
  UNLESS_CHAT_BOT: 'dashboard_unless_chatbot'
}
