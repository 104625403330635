// @flow
import type {
  Action,
  Thunk,
  DispatchFunc,
  GetStateFunc,
  Dictionary,
  FormattedCsvRow,
  Country,
  FormattedOrderItem,
  CsvFileType
} from '../../types'
import type { CsvUploadError } from '../types'
import { getCsvTemplates } from './getCsvItemTemplates'
import { getOrderItemDetails, addProductDescriptions } from './getItemDetails'
import { uploadArtworkUsingCsvUrls } from './uploadArtwork'
import csvsync from 'csvsync'
import { logLe, LOG_LEVEL } from '../logging'
import { updateCsvInserts } from './updateCsvInserts'
import { getOwnProperties } from '../../helpers/error'
import { entries, values } from '../../helpers/dictionary'
import { uploadPackingSlipsFromUrlsV2 } from './uploadPackingSlip'
import { addDummyImagesForNonImageProducts } from './addDummyImagesForNonImageProducts'
import { getV4ProductDetailsForCsvOrderItems } from './getV4ProductDetailsForCsvOrderItems'
import { getCsvUploadFileTypeFromHeaders, groupByMerchantOrderId, mapCsvRows } from '../../helpers/csvMapping'
import { initPrintAreasForCsvItem } from './initPrintAreasForCsvItem'

export const UPLOAD_CSV = 'UPLOAD_CSV'
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS'
export const UPLOAD_CSV_ERROR = 'UPLOAD_CSV_ERROR'

export const ACTION_TYPES__UPLOAD_CSV = [UPLOAD_CSV, UPLOAD_CSV_SUCCESS, UPLOAD_CSV_ERROR]

export function uploadCsv(file: File, countries: Country[]): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(startCsvUpload())
    const fileReader = new FileReader()

    fileReader.onload = function (e: any) {
      try {
        const result = e.target.result
        var data = csvsync.parse(result, {
          returnObject: true,
          trim: true
        })

        const csvFileType = getCsvUploadFileTypeFromHeaders(Object.keys(data[0]))
        const { orders, orderItems, productDescriptions } = mapCsvRows(data, countries, csvFileType)

        const formattedOrders = groupByMerchantOrderId(orders, orderItems)

        if (Object.keys(productDescriptions).length > 0) {
          dispatch(addProductDescriptions(productDescriptions))
        }

        const hasOrders = formattedOrders && values(formattedOrders).length > 0
        if (hasOrders) {
          dispatch(csvUploadSuccess(formattedOrders, orderItems, csvFileType))
        } else {
          throw new Error('No orders were formatted')
        }
      } catch (error) {
        const errorMessage = error.csvUploadErrorMessage ?? null
        const logEntriesProps = errorMessage ? { errorMessage } : { ...getOwnProperties(error) }

        dispatch(csvUploadError(errorMessage))
        dispatch(logLe(`Parsing csv failed`, logEntriesProps, LOG_LEVEL.ERROR))
      }
    }

    fileReader.readAsText(file)
  }
}

export function startCsvUpload(): Action {
  return {
    type: UPLOAD_CSV
  }
}

export function csvUploadSuccess(
  formattedOrders: Dictionary<FormattedCsvRow>,
  orderItems: Dictionary<FormattedOrderItem>,
  fileType: CsvFileType
): Thunk<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(initialiseCsvOrderBrandingData({ formattedOrders }))

    dispatch({
      type: UPLOAD_CSV_SUCCESS,
      formattedOrders,
      orderItems,
      fileType
    })

    await dispatch(getV4ProductDetailsForCsvOrderItems(orderItems))
    await dispatch(getOrderItemDetails(formattedOrders, orderItems))
    await dispatch(getCsvTemplates(orderItems))

    const orderItemsForImageUpload = {}
    entries(orderItems).forEach(([id, orderItem]) => {
      orderItemsForImageUpload[id] = { ...orderItem }
    })

    dispatch(initPrintAreasForCsvItem(orderItems, orderItemsForImageUpload))
    dispatch(uploadArtworkUsingCsvUrls(orderItemsForImageUpload))

    dispatch(uploadPackingSlipsFromUrlsV2(formattedOrders))

    dispatch(addDummyImagesForNonImageProducts(orderItems))
  }
}

export function csvUploadError(message: ?string): CsvUploadError {
  return {
    type: UPLOAD_CSV_ERROR,
    message
  }
}

function initialiseCsvOrderBrandingData({ formattedOrders }: {| formattedOrders: Dictionary<FormattedCsvRow> |}) {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    values(formattedOrders).forEach((order) => {
      if (order.branding.length === 0) {
        return
      }

      dispatch(updateCsvInserts({ inserts: order.branding, orderId: order.id }))
    })
  }
}
