// @flow
import { connect } from 'react-redux'
import type { ComponentType } from 'react'
import type {
  ThumbnailItem,
  CatalogueItem,
  Status,
  DispatchFunc,
  FormattedOrderItem,
  ArtworkTransformations,
  V4ProductDetails,
  MultiAssetTemplates,
  Artwork,
  AppState
} from '../../../../types'
import CsvImageModalController from './CsvImageModalController'
import {
  uploadCsvArtworkFromUrl,
  uploadCsvArtworkFromFile,
  updateCsvItemArtwork,
  removeCsvItemArtwork,
  onSelectCsvImageLibraryImage
} from '../../../../actions/csvUpload'
import { getCsvItemTemplates } from '../../../../selectors/csvUpload'
import { getAllArtworks, getAllArtworkTransformations, getArtworkProgress } from '../../../../selectors/images/images'
import type { Dictionary } from '../../../../types/dictionary'
import type { ArtworkProgress } from '../../../../types/images'
import { fetchPricingAndShippingForCsvOrder } from '../../../../actions/csvUpload/fetchPricingAndShippingForCsvOrder'

type OwnProps = {|
  thumbnailItem: ?ThumbnailItem,
  catalogueDetails: CatalogueItem,
  v4ProductDetails: V4ProductDetails,
  templateStatus: Status,
  imageStatus: Status,
  orderId: string,
  item: FormattedOrderItem,
  imageErrorMessage: string,
  closeModal: () => void
|}

type DispatchProps = {|
  removeArtwork: (itemId: string, printAreaName: string) => void,
  uploadCsvArtworkFromUrl: (itemId: string, url: string, isTilingEnabled: boolean) => void,
  uploadCsvArtworkFromFile: (itemId: string, file: File, isTilingEnabled: boolean, printAreaName: string) => void,
  updateCsvItemArtwork: (
    transformations: ArtworkTransformations,
    pigUrl: string,
    id: string,
    printAreaName: string,
    transformImageUrl: string
  ) => void,
  onSelectCsvImageLibraryImage: (selectionData: {|
    artwork: Artwork,
    itemId: string,
    isTilingEnabled: boolean,
    printAreaName: string
  |}) => void
|}

type StateProps = {|
  templates: ?MultiAssetTemplates,
  allArtworkProgress: Dictionary<ArtworkProgress>,
  artworks: Dictionary<Artwork>,
  artworkTransformations: Dictionary<ArtworkTransformations>
|}

function mapDispatchToProps(dispatch: DispatchFunc, ownProps: OwnProps): DispatchProps {
  return {
    uploadCsvArtworkFromUrl: (itemId: string, url: string, isTilingEnabled: boolean) => {
      dispatch(uploadCsvArtworkFromUrl({ url, itemIds: [itemId], isTilingEnabled }))
    },
    uploadCsvArtworkFromFile: (itemId: string, file: File, isTilingEnabled: boolean, printAreaName: string) => {
      dispatch(uploadCsvArtworkFromFile(file, itemId, isTilingEnabled, printAreaName))
    },
    onSelectCsvImageLibraryImage: ({ artwork, isTilingEnabled, itemId, printAreaName }) => {
      dispatch(onSelectCsvImageLibraryImage({ artwork, isTilingEnabled, itemId, printAreaName }))
      dispatch(fetchPricingAndShippingForCsvOrder(ownProps.orderId))
    },
    updateCsvItemArtwork: (
      transformations: ArtworkTransformations,
      pigUrl: string,
      id: string,
      printAreaName: string,
      transformImageUrl: string
    ) => {
      dispatch(updateCsvItemArtwork(transformations, pigUrl, id, printAreaName, transformImageUrl))
    },
    removeArtwork: (itemId: string, printAreaName: string) => {
      dispatch(removeCsvItemArtwork(itemId, printAreaName))
    }
  }
}

function mapRsaaStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    templates: getCsvItemTemplates(state, ownProps.item.id),
    artworks: getAllArtworks(state),
    allArtworkProgress: getArtworkProgress(state),
    artworkTransformations: getAllArtworkTransformations(state)
  }
}

export default (connect(mapRsaaStateToProps, mapDispatchToProps)(CsvImageModalController): ComponentType<OwnProps>)
