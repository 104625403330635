import { cn } from '../../../helpers'
import Skeleton from '../../Skeleton'

export function OrderInsertsLoading({ className = '' }: { className?: string }) {
  return (
    <div className={cn('border bg-white p-6', className)}>
      <h2 className="mt-0 text-lg">Inserts</h2>

      <Skeleton className="mt-6 h-16" />
    </div>
  )
}
