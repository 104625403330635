import { Link, useParams } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'

import {
  SalesChannelProductSuccessResponseInterface,
  useSalesChannelProduct
} from '../../hooks/useSalesChannelProduct.hook'
import NotFound from '../NotFound'
import { ROUTE_PATHS } from '../../constants'
import Banner, { BannerTypeEnum } from '../Banner'
import { mapV4AttributesToV3 } from '../../helpers'
import { useMerchantService, useUser } from '../../hooks'
import { ItemMetaDataInterface } from '../../interfaces'
import { PrintAreasArtworkDataType } from '../ImageEditor'
import { ProductShippingSelector, VariantData } from './components'
import { ProductPreview } from './components/ProductPreview.component'
import { VariantDataHeader } from './components/VariantDataHeader.component'
import { HeaderLogo } from '../SalesChannelConfigure/components/HeaderLogo.component'
import { ProductFulfilmentToggle } from './components/ProductFulfilmentToggle.component'
import { SalesChannelProductError } from './components/SalesChannelProductError.component'
import { SalesChannelProductLoading } from './components/SalesChannelProductLoading.component'

export interface VariantDataInterface {
  artworkData?: PrintAreasArtworkDataType | null
  assetRequirementData?: Record<string, { required: boolean }> | null
  id: string
  isAutoFulfilled: boolean
  isFulfilled: boolean
  selectedProductData?: {
    attributes: Record<string, string> | null
    metadata?: ItemMetaDataInterface
    price?: string
    productType: string
    sku: string
  } | null
  selectedPrintArea?: string | null
  title: string
  preferredShippingMethod: string | null
}

function getVariantsDataFromSalesChannelProduct(
  salesChannelProduct?: SalesChannelProductSuccessResponseInterface
): Record<string, VariantDataInterface> | null {
  if (!salesChannelProduct) {
    return null
  }

  const defaultVariantsData: Record<string, VariantDataInterface> = {}

  const variantsData = salesChannelProduct.data.variantsData ?? []

  variantsData.forEach((variantData) => {
    defaultVariantsData[variantData.id] = {
      ...variantData,
      selectedProductData: variantData.selectedProductData
        ? {
            ...variantData.selectedProductData,
            attributes: variantData.selectedProductData.attributes
              ? mapV4AttributesToV3(variantData.selectedProductData.attributes)
              : null
          }
        : null
    }
  })

  return Object.keys(defaultVariantsData).length > 0 ? defaultVariantsData : null
}

export default function SalesChannelProduct() {
  const { salesChannelId, productId } = useParams<{ salesChannelId: string; productId: string }>()
  const { merchantDetails } = useMerchantService()
  const { user } = useUser()
  const { salesChannelProduct, isLoadingSalesChannelProduct, salesChannelProductFetchError } = useSalesChannelProduct({
    salesChannelId,
    productId
  })
  const [variantsData, setVariantsData] = useState<Record<string, VariantDataInterface> | null>(() =>
    getVariantsDataFromSalesChannelProduct(salesChannelProduct)
  )

  useEffect(() => {
    const newVariantsData = getVariantsDataFromSalesChannelProduct(salesChannelProduct)
    setVariantsData(newVariantsData)

    if (salesChannelProduct && !newVariantsData) {
      window.analytics.track('Sales channels - No variants found error shown')
    }
  }, [salesChannelProduct])

  if (!merchantDetails) {
    throw Error('No merchant details')
  }

  if (!user) {
    throw Error('No user')
  }

  const salesChannel = merchantDetails.salesChannels.find((salesChannel) => salesChannel.id === salesChannelId)

  if (!salesChannel) {
    return (
      <div className="grid min-h-screen place-content-center">
        <NotFound>
          <Link className="btn btn-primary mx-auto mt-8" to={ROUTE_PATHS.SALES_CHANNELS.INDEX}>
            Back to sales channels
          </Link>
        </NotFound>
      </div>
    )
  }

  if (salesChannelProductFetchError) {
    const errorCode = `${salesChannelProductFetchError.responseBodyJson?.traceParent ?? '0'}-${
      salesChannelProductFetchError?.status ?? '0'
    }`
    return <SalesChannelProductError errorCode={errorCode} />
  }

  if (isLoadingSalesChannelProduct) {
    return <SalesChannelProductLoading />
  }

  if (!salesChannelProduct) {
    throw Error('No sales channel product')
  }

  const userHasToAddBillingDetails = !user.billing.hasPaymentSetup && user.billing.invoiceFrequency === 'PerOrder'

  return (
    <>
      <div className="flex flex-col justify-between gap-3 lg:flex-row lg:gap-8">
        <div className="max-w-7xl">
          <header className="flex flex-col">
            <div className="flex items-center gap-2">
              <HeaderLogo platformName={salesChannel.platform} />
              <h1 className="ml-2 truncate">{salesChannelProduct.data.productData.name}</h1>
            </div>
          </header>

          <div className="pt-4">Configure how Prodigi will fulfil this item.</div>

          <div className="pt-4">
            Add a product and image below and we&apos;ll automatically fulfil this item whenever it&apos;s ordered in
            your store. Otherwise, leave empty and you&apos;ll be prompted as orders arrive.
          </div>
        </div>

        <ProductFulfilmentToggle salesChannelProduct={salesChannelProduct} />
      </div>

      {userHasToAddBillingDetails && (
        <div className="mt-8">
          <Banner
            type={BannerTypeEnum.Error}
            buttonLink={ROUTE_PATHS.SETTINGS.BILLING}
            buttonText="Add billing details"
            message="You will need to set up billing details to automatically fulfil orders"
          />
        </div>
      )}

      <div className="gap-6 pt-12 lg:flex lg:flex-wrap">
        <div>
          <ProductPreview salesChannel={salesChannel} salesChannelProduct={salesChannelProduct} />
          <ProductShippingSelector
            className="hidden lg:inline"
            salesChannel={salesChannel}
            salesChannelProduct={salesChannelProduct}
            variantsData={variantsData}
          />
        </div>

        <div className="flex max-w-full flex-1 flex-col gap-6">
          <SalesChannelProductVariants variantsData={variantsData} onChangeVariantsData={setVariantsData} />
        </div>

        <div className="mt-12 max-w-full flex-1 flex-col lg:hidden">
          <ProductShippingSelector
            className="lg:hidden"
            salesChannel={salesChannel}
            salesChannelProduct={salesChannelProduct}
            variantsData={variantsData}
          />
        </div>
      </div>
    </>
  )
}

export type VariantsSkuDataType = Record<string, { productType: string } | undefined>

function SalesChannelProductVariants({
  variantsData,
  onChangeVariantsData
}: {
  variantsData: Record<string, VariantDataInterface> | null
  onChangeVariantsData: (newVariantsData: Record<string, VariantDataInterface>) => void
}) {
  const [variantsSkuData, setVariantsSkuData] = useState<VariantsSkuDataType>({})

  const handleVariantsSkuDataChange = useCallback(
    ({ variantId, productType }: { variantId: string; productType: string }) => {
      setVariantsSkuData((state) => ({ ...state, [variantId]: { productType } }))
    },
    []
  )

  if (!variantsData) {
    return (
      <NotFound
        className="mx-auto"
        heading="No variants found"
        body={<p className="break-words text-center">This product has no variants available for configuration</p>}
      >
        <Link className="btn btn-primary mx-auto mt-8" to={ROUTE_PATHS.SALES_CHANNELS.INDEX}>
          Back to sales channels
        </Link>
      </NotFound>
    )
  }

  function handleChangeVariantData(newVariantData: VariantDataInterface) {
    onChangeVariantsData({ ...variantsData, [newVariantData.id]: newVariantData })
  }

  const orderedVariantsByTitle = Object.entries(variantsData).sort(function (a, b) {
    const titleA = a[1].title.toUpperCase()
    const titleB = b[1].title.toUpperCase()

    return titleA.localeCompare(titleB)
  })

  return (
    <>
      {orderedVariantsByTitle.map(([variantId, variantData]) => (
        <div className="overflow-hidden" key={variantId}>
          <div className="border border-gray-200 bg-white p-8">
            <VariantDataHeader variantData={variantData} />
          </div>

          <AnimatePresence initial={false}>
            {variantData.isFulfilled && (
              <motion.div
                className="border border-t-0 border-gray-200 bg-white"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
              >
                <VariantData
                  variantData={variantData}
                  allVariantsData={variantsData}
                  variantsSkuData={variantsSkuData}
                  onChangeVariantData={handleChangeVariantData}
                  onLoadSkuData={handleVariantsSkuDataChange}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </>
  )
}
