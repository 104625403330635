import { useState } from 'react'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import { StatusEnum } from '../../../enums'
import { ProPlanBillingRadioButtons } from '.'
import { ProBillingOption } from '../interfaces'
import ConfirmationModal from '../../ConfirmationModal'
import { PRO_PRO_FEATURE_SHORT_LIST } from '../constants'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../Settings/constants'

export function ChangeToProModal({
  isModalOpen,
  proPlanBillingOptions,
  selectedProSubscriptionTier,
  setIsModalOpen,
  setSelectedProSubscriptionTier
}: {
  isModalOpen: boolean
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedProSubscriptionTier: ProBillingOption
  setIsModalOpen: (open: boolean) => void
  setSelectedProSubscriptionTier: React.Dispatch<React.SetStateAction<ProBillingOption>>
}) {
  const [updateStatus, setUpdateStatus] = useState(StatusEnum.Idle)

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    }
    setIsModalOpen(isOpen)
  }

  function switchToPro() {
    console.log('Switch to Pro')
    handleOpenChange(false)
    setUpdateStatus(StatusEnum.Success)
    // TODO: add request to BE
    // handle failure
    // on success: dispatch(refreshAccessToken())
  }

  const isLoading = updateStatus === StatusEnum.Loading

  return (
    <ConfirmationModal
      className="relative max-w-[95vw] bg-white sm:min-w-[600px]"
      contentClassName="max-h-60vh overflow-auto pb-4 text-base max-w-4xl"
      open={isModalOpen}
      title="Complete your Pro subscription"
      continueButton={{
        variant: 'primary',
        text: 'Active Pro'
      }}
      footer={
        <p className="mt-8 text-gray-600">
          We&apos;ll process your first payment today using your existing billing details. Your subscription will
          automatically renew each {selectedProSubscriptionTier === 'ProMonthlyBilling' ? 'month' : 'year'} on{' '}
          {format(new Date(), 'do')}. Cancel any time online {'–'} you can continue using your Pro benefits until the
          end of your current billing period.
        </p>
      }
      closeButton={{ variant: 'tertiary', theme: 'greyscale', text: 'Close' }}
      isLoading={isLoading}
      setOpen={handleOpenChange}
      onCancel={() => handleOpenChange(false)}
      onContinue={switchToPro}
    >
      <p className="font-medium">You&apos;re about to unlock exclusive benefits including:</p>
      <ul className="mb-4 mt-2 max-w-fit list-inside list-disc lg:mb-8">
        {PRO_PRO_FEATURE_SHORT_LIST.map((feature) => (
          <li className="mt-2" key={feature}>
            {feature}
          </li>
        ))}
      </ul>

      <h3 className="m-0 mb-4 mt-16 font-medium">Choose your billing plan</h3>
      <ProPlanBillingRadioButtons
        proPlanBillingOptions={proPlanBillingOptions}
        selectedSubscriptionTier={selectedProSubscriptionTier}
        setSelectedSubscriptionTier={setSelectedProSubscriptionTier}
      />
    </ConfirmationModal>
  )
}
