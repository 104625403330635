// @flow
import type { PwintyJwt } from '../types'
import jwtDecode from 'jwt-decode'
import { selectJwtIssuer } from '../selectors/appSettings'

export function decodePwintyJwt(jwt: string): PwintyJwt {
  const decoded = jwtDecode(jwt)

  return {
    rawToken: jwt,
    expiry: decoded.exp * 1000,
    issuer: decoded.iss,
    userPrincipal: {
      id: decoded.sub,
      merchantUniqueId: parseInt(decoded.merchId),
      name: decoded.name,
      email: decoded.email,
      roles: decoded.roles
    }
  }
}

export function isPwintyToken(jwt: string): boolean {
  const decoded = decodePwintyJwt(jwt)
  const jwtIssuer = selectJwtIssuer()

  return decoded.issuer === jwtIssuer
}
