import { CatalogueProductDetailsInterface } from '../interfaces/catalogueProducts.interface'

export const CATALOGUE_PRODUCTS: Record<string, CatalogueProductDetailsInterface | undefined> = {
  'Prints & posters': {
    image: '/img/v2/product-catalogue/prints.jpg',
    productTypes: {
      'Art prints': {
        image: '/img/v2/product-catalogue/prints_fine-art-prints.jpg'
      },
      'Photo prints': {
        image: '/img/v2/product-catalogue/prints_c-types.jpg'
      },
      'Specialist prints': {
        image: '/img/v2/product-catalogue/metallic-foil-prints.jpg'
      },
      Posters: {
        image: '/img/v2/product-catalogue/prints_posters.jpg'
      },
      'Mounted prints': {
        image: '/img/v2/product-catalogue/prints_mounted-prints.jpg'
      },
      'Poster hangers with prints': {
        image: '/img/v2/product-catalogue/poster-hangers-with-prints.jpg'
      }
    }
  },
  'Wall art': {
    image: '/img/v2/product-catalogue/framed-prints.jpg',
    productTypes: {
      'Stretched canvas': {
        image: '/img/v2/product-catalogue/canvases.jpg'
      },
      'Framed Canvas': {
        image: '/img/v2/product-catalogue/canvases_framed-canvas.jpg'
      },
      'Rolled canvas': {
        image: '/img/v2/product-catalogue/canvases_rolled-canvas.jpg'
      },
      'Framed prints': {
        image: '/img/v2/product-catalogue/framed-prints.jpg'
      },
      'Photo tiles': {
        image: '/img/v2/product-catalogue/photo-tiles.jpg'
      }
    }
  },
  Stickers: {
    image: '/img/v2/product-catalogue/stickers.jpg',
    productTypes: {}
  },
  Tattoos: {
    image: '/img/v2/product-catalogue/tattoos.jpg',
    productTypes: {}
  },
  Magnets: {
    image: '/img/v2/product-catalogue/magnets.jpg',
    productTypes: {}
  },
  Patches: {
    image: '/img/v2/product-catalogue/patches.jpg',
    productTypes: {}
  },
  'Device Cases': {
    image: '/img/v2/product-catalogue/technology.jpg',
    productTypes: {
      'Phone Case': {
        image: '/img/v2/product-catalogue/technology_phone-case.jpg'
      },
      'Tablet Case': {
        image: '/img/v2/product-catalogue/technology_tablet-case.jpg'
      },
      'Watch Strap': {
        image: '/img/v2/product-catalogue/technology_watch-strap.jpg'
      }
    }
  },
  Technology: {
    image: '/img/v2/product-catalogue/gaming-mats.jpg',
    productTypes: {
      'Desk Mats': {
        image: '/img/v2/product-catalogue/gaming-mat.jpg'
      },
      'Mouse Mats': {
        image: '/img/v2/product-catalogue/mouse-mat.jpeg'
      }
    }
  },
  'Gallery Boards': {
    image: '/img/v2/product-catalogue/gallery-boards.jpg',
    productTypes: {
      'Gallery Boards': {
        image: '/img/v2/product-catalogue/gallery-boards_gallery-boards.jpg'
      },
      'Picture Stands': {
        image: '/img/v2/product-catalogue/gallery-boards_picture-stands.jpg'
      }
    }
  },
  Panels: {
    image: '/img/v2/product-catalogue/panels.jpg',
    productTypes: {
      'Acrylic Panel': {
        image: '/img/v2/product-catalogue/panels_acrylic-panel.jpg'
      },
      Foamex: {
        image: '/img/v2/product-catalogue/panels_foamex.jpg'
      },
      Gatorboard: {
        image: '/img/v2/product-catalogue/panels_gatorboard.jpg'
      },
      'Photo Tiles': {
        image: '/img/v2/product-catalogue/panels_photo-tiles.jpg'
      }
    }
  },
  Metal: {
    image: '/img/v2/product-catalogue/metal.jpg',
    productTypes: {}
  },
  Drinkware: {
    image: '/img/v2/product-catalogue/home-and-living.jpg',
    productTypes: {
      Mugs: {
        image: '/img/v2/product-catalogue/home-and-living_mugs.jpg'
      },
      'Water bottles': {
        image: '/img/v2/product-catalogue/sport-and-games_water-bottles.jpg'
      }
    }
  },
  'Home & Living': {
    image: '/img/v2/product-catalogue/home-and-living_cushions.jpg',
    productTypes: {
      'Acrylic Prism': {
        image: '/img/v2/product-catalogue/home-and-living_acrylic-prism.jpg'
      },
      Aprons: {
        image: '/img/v2/product-catalogue/home-and-living_aprons.jpg'
      },
      Bags: {
        image: '/img/v2/product-catalogue/home-and-living_bags.jpg'
      },
      'Bath Mats': {
        image: '/img/v2/product-catalogue/home-and-living_bath-mats.jpg'
      },
      Blankets: {
        image: '/img/v2/product-catalogue/home-and-living_blankets.jpg'
      },
      'Christmas tree ornaments': {
        image: '/img/v2/product-catalogue/home-and-living_christmas-tree-ornaments.jpg'
      },
      Clocks: {
        image: '/img/v2/product-catalogue/home-and-living_clocks.jpg'
      },
      Coasters: {
        image: '/img/v2/product-catalogue/home-and-living_coasters.jpg'
      },
      Cushions: {
        image: '/img/v2/product-catalogue/home-and-living_cushions.jpg'
      },
      'Cushions (Cover Only)': {
        image: '/img/v2/product-catalogue/home-and-living_cushions-cover-only.jpg'
      },
      Kitchenwares: {
        image: '/img/v2/product-catalogue/home-and-living_kitchenwares.jpg'
      },
      'Mouse mats': {
        image: '/img/v2/product-catalogue/home-and-living_mouse-mats.jpg'
      },
      Pendants: {
        image: '/img/v2/product-catalogue/home-and-living_pendants.jpg'
      },
      'Pet Beds': {
        image: '/img/v2/product-catalogue/home-and-living_pet-beds.jpg'
      },
      'Pet tags': {
        image: '/img/v2/product-catalogue/home-and-living_pet-tags.jpg'
      },
      'Pillow Case': {
        image: '/img/v2/product-catalogue/home-and-living_pillow-case.jpg'
      },
      Placemats: {
        image: '/img/v2/product-catalogue/home-and-living_placemats.jpg'
      },
      'Shower Curtains': {
        image: '/img/v2/product-catalogue/home-and-living_shower-curtains.jpg'
      },
      Tapestries: {
        image: '/img/v2/product-catalogue/home-and-living_tapestries.jpg'
      },
      Towels: {
        image: '/img/v2/product-catalogue/home-and-living_towels.jpg'
      },
      'Wash Bags': {
        image: '/img/v2/product-catalogue/home-and-living_wash-bags.jpg'
      }
    }
  },
  Pets: {
    image: '/img/v2/product-catalogue/pets.jpg',
    productTypes: {}
  },
  'Sports & games': {
    image: '/img/v2/product-catalogue/sport-and-games.jpg',
    productTypes: {
      Jigsaws: {
        image: '/img/v2/product-catalogue/sport-and-games_jigsaws.jpg'
      },
      'Playing Cards': {
        image: '/img/v2/product-catalogue/sport-and-games_playing-cards.jpg'
      },
      Sports: {
        image: '/img/v2/product-catalogue/sport-and-games_pickleball-paddles.jpg'
      },
      'Yoga Mat': {
        image: '/img/v2/product-catalogue/sport-and-games_yoga-mat.jpg'
      },
      Skateboards: {
        image: '/img/v2/product-catalogue/sport-and-games_skateboards.jpg'
      }
    }
  },
  Stationery: {
    image: '/img/v2/product-catalogue/stationery.jpg',
    productTypes: {
      'Greetings Cards': {
        image: '/img/v2/product-catalogue/stationery_greetings-cards.jpg'
      },
      Journals: {
        image: '/img/v2/product-catalogue/stationery_journals.jpg'
      },
      Notebooks: {
        image: '/img/v2/product-catalogue/stationery_notebooks.jpg'
      },
      Postcards: {
        image: '/img/v2/product-catalogue/stationery_postcards.jpg'
      },
      'Square Invitations': {
        image: '/img/v2/product-catalogue/stationery_square-invitations.jpg'
      },
      'Wrapping paper': {
        image: '/img/v2/product-catalogue/stationery_wrapping-paper.jpg'
      }
    }
  },
  Apparel: {
    image: '/img/v2/product-catalogue/apparel.jpg',
    productTypes: {
      'Baby Bodysuits': {
        image: '/img/v2/product-catalogue/apparel_baby-bodysuits.jpg'
      },
      'Baby T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_baby-t-shirts.jpg'
      },
      'Face Masks': {
        image: '/img/v2/product-catalogue/apparel_face-masks.jpg'
      },
      'Kids Hoodies': {
        image: '/img/v2/product-catalogue/apparel_kids-hoodies.jpg'
      },
      'Kids Sweatshirts': {
        image: '/img/v2/product-catalogue/apparel_kids-sweatshirts.jpg'
      },
      'Kids T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_kids-t-shirts.jpg'
      },
      'Mens Hoodies': {
        image: '/img/v2/product-catalogue/apparel_mens-hoodies.jpg'
      },
      'Mens Long Sleeved T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_mens-long-sleeved-t-shirts.jpg'
      },
      'Mens Sweatshirts': {
        image: '/img/v2/product-catalogue/apparel_mens-sweatshirts.jpg'
      },
      'Mens T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_mens-t-shirts.jpg'
      },
      'Mens Tanktops': {
        image: '/img/v2/product-catalogue/apparel_mens-tanktops.jpg'
      },
      Socks: {
        image: '/img/v2/product-catalogue/apparel_socks.jpg'
      },
      'Unisex Baseball T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_unisex-baseball-t-shirts.jpg'
      },
      'Unisex Hoodies': {
        image: '/img/v2/product-catalogue/apparel_unisex-hoodies.jpg'
      },
      'Unisex Long Sleeved T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_unisex-long-sleeved-t-shirts.jpg'
      },
      'Unisex Sweatshirts': {
        image: '/img/v2/product-catalogue/apparel_unisex-sweatshirts.jpg'
      },
      'Unisex T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_unisex-t-shirts.jpg'
      },
      'Unisex Tanktops': {
        image: '/img/v2/product-catalogue/apparel_unisex-tanktops.jpg'
      },
      'Womens Hoodies': {
        image: '/img/v2/product-catalogue/apparel_womens-hoodies.jpg'
      },
      'Womens Long Sleeved T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_womens-long-sleeved-t-shirts.jpg'
      },
      'Womens Sweatshirts': {
        image: '/img/v2/product-catalogue/apparel_womens-sweatshirts.jpg'
      },
      'Womens T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_womens-t-shirts.jpg'
      },
      'Womens Tanktops': {
        image: '/img/v2/product-catalogue/apparel_womens-tanktops.jpg'
      }
    }
  },
  Accessories: {
    image: '/img/v2/product-catalogue/canvas-tote-bags.jpg',
    productTypes: {}
  },
  Footwear: {
    image: '/img/v2/product-catalogue/footwear.jpg',
    productTypes: {}
  },
  'Sample Pack': {
    image: '/img/v2/product-catalogue/sample-pack.jpg',
    productTypes: {}
  }
}
