import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import {
  BrandedInsertsOrderModal,
  OrderInsertsContent,
  OrderInsertsError,
  OrderInsertsLoading,
  OrderInsertsNoContent
} from '../../BrandedInsertsOrderModal'
import Button from '../../Button'
import { createErrorToast, createToast } from '../../Toast'
import { fetcher, requestImageLibraryThumbnailGeneration } from '../../../helpers'
import { FetchErrorInterface, InsertDataType, StatusType } from '../../../interfaces'
import { BrandedInsertIdType, useBrandingDetails, useMerchantBranding, useOrderDetail } from '../../../hooks'

const ORDER_EDIT_INSERT_TOAST_ID = 'prodigiOrderEditBrandedInsertToast'

export function OrderEditBrandedInserts() {
  const { id: orderId } = useParams<{ id: string }>()
  const { mutateOrderDetails, orderDetailsResponse } = useOrderDetail(orderId)
  const { merchantBrandingResponse, isLoadingMerchantBranding, merchantBrandingFetchError } = useMerchantBranding()
  const { brandingDetails, brandingDetailsFetchError, isLoadingBrandingDetails } = useBrandingDetails()
  const orderDetail = orderDetailsResponse?.data

  const [saveStatus, setSaveStatus] = useState<StatusType>('idle')
  const [isOrderBrandedInsertsModalOpen, setIsOrderBrandedInsertsModalOpen] = useState(false)

  const orderBrandedInserts: InsertDataType[] = Object.entries(orderDetail?.order.branding.inserts ?? {}).map(
    ([insertId, { url }]) => {
      return {
        id: insertId as BrandedInsertIdType,
        imageUrl: url
      }
    }
  )

  async function handleSaveBrandedInserts(newBrandedInserts: InsertDataType[]) {
    toast.dismiss(ORDER_EDIT_INSERT_TOAST_ID)
    setSaveStatus('loading')

    try {
      const brandedInsertsImageRequests = newBrandedInserts
        .filter((brandedInsert) => Boolean(brandedInsert.imageLibraryId))
        .map((brandedInsert) => requestImageLibraryThumbnailGeneration(brandedInsert.imageLibraryId ?? ''))

      const brandedInsertsImageGenerationResponses = await Promise.all(brandedInsertsImageRequests)

      const brandedInsertsForOrderWithUpdatedUrls: InsertDataType[] = newBrandedInserts.map((brandedInsert) => {
        const imageData = brandedInsertsImageGenerationResponses.find(
          (imageResponse) => imageResponse.public_id === brandedInsert.imageLibraryId
        )

        return {
          ...brandedInsert,
          imageLibraryId: imageData?.public_id ?? brandedInsert.imageLibraryId,
          imageUrl: imageData?.url ?? brandedInsert.imageUrl
        }
      })

      const insertsPayload: Record<string, { url: string }> = {}
      brandedInsertsForOrderWithUpdatedUrls.forEach((insert) => {
        if (!insert.imageUrl) {
          return
        }

        insertsPayload[insert.id] = {
          url: insert.imageUrl
        }
      })

      await fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/branding/`, {
        body: JSON.stringify({
          inserts: insertsPayload
        }),
        method: 'PUT'
      })
      await mutateOrderDetails()

      setSaveStatus('success')
      handleOpenChange(false)
      createToast({ type: 'success', heading: 'Inserts updated' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string; imageLibraryId?: string }>
      const errorCode = `${errorResponse?.status ?? 0} ${errorResponse?.responseBodyJson?.imageLibraryId ?? 0}`
      createErrorToast({
        errorCode,
        heading: 'Failed to update inserts',
        id: ORDER_EDIT_INSERT_TOAST_ID
      })
      setSaveStatus('error')
    }
  }

  async function handleRemoveAllInserts() {
    await handleSaveBrandedInserts([])
  }

  if (brandingDetailsFetchError) {
    return (
      <OrderInsertsError
        orderBrandedInserts={orderBrandedInserts}
        code={`BDFE-${brandingDetailsFetchError.status ?? 0}`}
        saveStatus={saveStatus}
        onRemove={handleRemoveAllInserts}
      />
    )
  }

  if (merchantBrandingFetchError) {
    return (
      <OrderInsertsError
        orderBrandedInserts={orderBrandedInserts}
        code={`MBFE-${merchantBrandingFetchError.status ?? 0}`}
        saveStatus={saveStatus}
        onRemove={handleRemoveAllInserts}
      />
    )
  }

  if (isLoadingBrandingDetails || !brandingDetails || isLoadingMerchantBranding || !merchantBrandingResponse) {
    return <OrderInsertsLoading />
  }

  const hasInserts = orderBrandedInserts.length > 0
  const merchantBranding = merchantBrandingResponse.data

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setSaveStatus('idle')
      toast.dismiss(ORDER_EDIT_INSERT_TOAST_ID)
    }
    setIsOrderBrandedInsertsModalOpen(isOpen)
  }

  return (
    <div className="border bg-white p-6">
      <div className="flex items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        <div className="ml-auto">
          <Button variant="secondary" size="sm" onClick={() => handleOpenChange(true)}>
            {hasInserts ? 'View & edit' : 'Add inserts'}
          </Button>
        </div>
      </div>

      {hasInserts ? (
        <OrderInsertsContent brandingDetails={brandingDetails} orderBrandedInserts={orderBrandedInserts} />
      ) : (
        <OrderInsertsNoContent />
      )}

      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={orderBrandedInserts}
        insertSets={merchantBranding.insertSets}
        saveStatus={saveStatus}
        open={isOrderBrandedInsertsModalOpen}
        setOpen={handleOpenChange}
        onSave={handleSaveBrandedInserts}
        onClose={() => handleOpenChange(false)}
      />
    </div>
  )
}
