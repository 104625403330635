// @flow
import type { Thunk, Dictionary, CatalogueItem, DispatchFunc, GetStateFunc } from '../../types'
import { onChangeProductDetails } from './onChangeProductDetails'
import { selectCsvOrder, getCsvOrderItem } from '../../selectors/csvUpload'
import type { BasketItemMetaDataType } from '../../types/basketItem'

export const SELECT_PRODUCT_FOR_CSV_ORDER = 'SELECT_PRODUCT_FOR_CSV_ORDER'

export function selectProductForCsvOrder(
  orderId: string,
  itemId: string,
  product: CatalogueItem,
  selectedAttributes: Dictionary<string>,
  metaData?: BasketItemMetaDataType
): Thunk<*> {
  window.analytics.track('Select Product - CSV Uploader', { sku: product.sku }, { label: 'csv uploader' })

  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const orderItemBeforeUpdate = getCsvOrderItem(getState(), itemId)

    if (!orderItemBeforeUpdate) {
      return
    }

    dispatch({
      type: SELECT_PRODUCT_FOR_CSV_ORDER,
      orderId,
      itemId,
      product,
      selectedAttributes,
      metaData
    })

    const updatedOrder = selectCsvOrder(getState(), orderId)

    dispatch(onChangeProductDetails(updatedOrder, orderItemBeforeUpdate, product.sku, selectedAttributes))
  }
}
