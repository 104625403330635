import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import Button from '../../Button'
import FormItem from '../../FormItem'
import SelectField from '../../SelectField'
import { formatCost } from '../../../helpers'
import { ROUTE_PATHS } from '../../../constants'
import { InsertSetType, InsertDataType } from '../../../interfaces'
import { BrandingDataInterface, BrandingDetailsType } from '../../../hooks'

export function InsertsSetPanel({
  brandingDetails,
  insertSets,
  selectedInsertSetId,
  onChangeAllInsertsData,
  onChangeSelectedInsertSetId
}: {
  brandingDetails: BrandingDataInterface
  insertSets: InsertSetType[]
  selectedInsertSetId: string
  onChangeAllInsertsData: (newAllInsertsData: InsertDataType[]) => void
  onChangeSelectedInsertSetId: (newSelectedInsertSetId: string) => void
}) {
  if (insertSets.length === 0) {
    return (
      <div className="text-black">
        No insert sets defined.{' '}
        <Link className="text-purple-500" to={ROUTE_PATHS.SETTINGS.BRANDING}>
          Create sets in settings
        </Link>
      </div>
    )
  }

  const selectedInsertSetDetails = insertSets.find((insertSet) => insertSet.id === selectedInsertSetId)

  return (
    <div className="flex flex-col gap-10 md:flex-row">
      <div className="flex-1">
        <FormItem
          className="w-full"
          labelText="Select a set"
          labelClassName="mt-0 text-black"
          inputField={
            <SelectField
              value={selectedInsertSetId}
              onChange={(event) => onChangeSelectedInsertSetId(event?.target?.value ?? '')}
            >
              <option disabled value="">
                Select insert set...
              </option>

              {insertSets.map((insertSet) => {
                return (
                  <option key={insertSet.id} value={insertSet.id}>
                    {insertSet.name}
                  </option>
                )
              })}
            </SelectField>
          }
        />
        {Boolean(selectedInsertSetDetails) && (
          <div className="mt-4 text-sm">Assigning a set will replace any existing inserts on this order.</div>
        )}
      </div>

      <div className="flex-1">
        {selectedInsertSetDetails ? (
          <InsertSetDetails
            brandingDetails={brandingDetails}
            insertSet={selectedInsertSetDetails}
            onChangeAllInsertsData={onChangeAllInsertsData}
          />
        ) : null}
      </div>
    </div>
  )
}

function InsertSetDetails({
  brandingDetails,
  insertSet,
  onChangeAllInsertsData
}: {
  brandingDetails: BrandingDataInterface
  insertSet: InsertSetType
  onChangeAllInsertsData: (newAllInsertsData: InsertDataType[]) => void
}) {
  const brandingDetailsForInsertSet: BrandingDetailsType[] = []

  insertSet.inserts.forEach((insert) => {
    const brandingDetailForInsert = brandingDetails.branding.find((brandingDetail) => brandingDetail.id === insert.id)
    if (!brandingDetailForInsert) {
      return
    }
    brandingDetailsForInsertSet.push(brandingDetailForInsert)
  })

  const insertSetCost = {
    amount: brandingDetailsForInsertSet.reduce(
      (amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount,
      0
    ),
    currency: brandingDetailsForInsertSet[0]?.cost?.currency
  }

  return (
    <div className="relative">
      <InsertSetDetailArrow />

      <div className="isolate rounded-lg bg-gray-100 px-10 py-8">
        <div className="flex justify-between gap-2 font-medium text-black">
          <div style={{ wordBreak: 'break-word' }}>{insertSet.name}</div>
          <div>{formatCost({ amount: insertSetCost.amount.toString(), currencyCode: insertSetCost.currency })}</div>
        </div>

        <div className="mt-4 text-sm">
          {brandingDetailsForInsertSet.length > 0 ? (
            <>
              {brandingDetailsForInsertSet.map((brandingDetail) => (
                <div key={brandingDetail.id}>{brandingDetail.name}</div>
              ))}
            </>
          ) : (
            <>No inserts in this set</>
          )}
        </div>

        <div className="mt-8">
          <div className="w-fit bg-white">
            <Button
              variant="secondary"
              onClick={() => {
                onChangeAllInsertsData(insertSet.inserts)
                toast.success('Set assigned')
              }}
            >
              Use this set
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

function InsertSetDetailArrow() {
  return (
    <>
      <div
        className="absolute left-0 top-[21px] hidden h-[52px] w-[52px] bg-gray-100 md:block"
        style={{ transform: 'rotate(45deg)' }}
      ></div>

      <div
        className="absolute left-1/2 block h-[52px] w-[52px] bg-gray-100 md:hidden"
        style={{ transform: 'translateX(-50%) rotate(45deg)' }}
      ></div>
    </>
  )
}
