import { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import {
  CancelProSubscriptionModal,
  ChangeToProModal,
  CoreSubscriptionPanel,
  EnterpriseSubscriptionPanel,
  ProSubscriptionPanel,
  ViewProSubscriptionDetailsModal
} from './components'
import { useUser } from '../../hooks'
import { cn, isProUser } from '../../helpers'
import { getProBillingOptions } from './helpers'
import { SubscriptionTierType } from '../../interfaces'
import { BillingPlanType, ProBillingOption, SelectedPlan } from './interfaces'

export default function BilingSubscriptionPlans() {
  const { user } = useUser()

  if (!user) {
    throw Error('No user')
  }

  const userSubscription = getUserSubscription(user.merchantSubscriptionSettings.subscriptionTier)
  const proPricing = getProBillingOptions(user.merchantSubscriptionSettings.subscriptionTierPricing)

  return (
    <BilingPlansPanel
      currency={Object.values(proPricing)[0].currency}
      proPricing={proPricing}
      userBillingPlan={{
        id: userSubscription,
        subscriptionTier: user.merchantSubscriptionSettings.subscriptionTier
      }}
      userHasToAddBillingDetails={!user.billing.hasPaymentSetup}
    />
  )
}

function getUserSubscription(subscriptionTier: SubscriptionTierType): BillingPlanType {
  if (isProUser(subscriptionTier)) {
    return 'Pro'
  }

  if (subscriptionTier === 'Enterprise') {
    return 'Enterprise'
  }

  return 'Core'
}

function BilingPlansPanel({
  currency,
  proPricing,
  userBillingPlan,
  userHasToAddBillingDetails
}: {
  currency: string
  proPricing: Record<ProBillingOption, { cost: number; currency: string }>
  userBillingPlan: SelectedPlan
  userHasToAddBillingDetails: boolean
}) {
  const [isChangeToProModalOpen, setIsChangeToProModalOpen] = useState(false)
  const [isViewSubscriptionModalOpen, setIsViewSubscriptionModalOpen] = useState(false)
  const [isCancelProSubscriptionModalOpen, setIsCancelProSubscriptionModalOpen] = useState(false)

  const [selectedProSubscriptionTier, setSelectedProSubscriptionTier] = useState<ProBillingOption>(
    userBillingPlan.subscriptionTier === 'ProMonthlyBilling' ? 'ProMonthlyBilling' : 'ProYearlyBilling'
  )

  return (
    <>
      <div id="BillingPlans" className="gap-y-12 border border-b-0 bg-white px-12 py-16 md:gap-x-8 lg:gap-y-0">
        <div className="col-span-4 flex flex-col pb-6 md:col-span-4 lg:col-span-4">
          <h2>Plans</h2>

          {userHasToAddBillingDetails && (
            <div className="mt-6 inline-flex items-center space-x-6 border border-magenta-700 bg-magenta-100 p-4 text-magenta-700">
              <span className="flex items-center">
                <ExclamationCircleIcon aria-hidden="false" className="h-8 w-8 text-magenta-700" />
              </span>
              <span>You will need to set up billing details before you can update your plan</span>
            </div>
          )}
        </div>

        <div className="mt-8 lg:col-span-full lg:col-start-6 xl:col-span-6 xl:col-start-5">
          <ul className="grid gap-y-6 xl:grid-cols-3 xl:gap-x-6">
            <li className={cn(userHasToAddBillingDetails && 'pointer-events-none opacity-50')}>
              <CoreSubscriptionPanel
                currency={currency}
                userBillingPlan={userBillingPlan}
                userHasToAddBillingDetails={userHasToAddBillingDetails}
                setIsCancelProSubscriptionModalOpen={setIsCancelProSubscriptionModalOpen}
              />
            </li>
            <li className={cn(userHasToAddBillingDetails && 'pointer-events-none opacity-50')}>
              <ProSubscriptionPanel
                proPlanBillingOptions={proPricing}
                userBillingPlan={userBillingPlan}
                userHasToAddBillingDetails={userHasToAddBillingDetails}
                selectedProSubscriptionTier={selectedProSubscriptionTier}
                setIsChangeToProModalOpen={setIsChangeToProModalOpen}
                setIsViewSubscriptionModalOpen={setIsViewSubscriptionModalOpen}
                setSelectedProSubscriptionTier={setSelectedProSubscriptionTier}
              />
            </li>
            <li className={cn(userHasToAddBillingDetails && 'pointer-events-none opacity-50')}>
              <EnterpriseSubscriptionPanel userBillingPlan={userBillingPlan} />
            </li>
          </ul>
        </div>
      </div>

      <ChangeToProModal
        isModalOpen={isChangeToProModalOpen}
        proPlanBillingOptions={proPricing}
        selectedProSubscriptionTier={selectedProSubscriptionTier}
        setIsModalOpen={setIsChangeToProModalOpen}
        setSelectedProSubscriptionTier={setSelectedProSubscriptionTier}
      />

      <ViewProSubscriptionDetailsModal
        isModalOpen={isViewSubscriptionModalOpen}
        proPlanBillingOptions={proPricing}
        selectedProSubscriptionTier={selectedProSubscriptionTier}
        userBillingPlan={userBillingPlan}
        openCancelProPlanModal={() => {
          setIsViewSubscriptionModalOpen(false)
          setIsCancelProSubscriptionModalOpen(true)
        }}
        setIsModalOpen={setIsViewSubscriptionModalOpen}
        setSelectedProSubscriptionTier={setSelectedProSubscriptionTier}
      />

      <CancelProSubscriptionModal
        isModalOpen={isCancelProSubscriptionModalOpen}
        setIsModalOpen={setIsCancelProSubscriptionModalOpen}
      />
    </>
  )
}
