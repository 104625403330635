import { useState } from 'react'
import toast from 'react-hot-toast'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

import Button from '../../../../../Button'
import Skeleton from '../../../../../Skeleton'
import { useDispatch } from 'react-redux'
import { useUser } from '../../../../../../hooks'
import OverlayPortal from '../../../../../OverlayPortal'
import { ROUTE_PATHS } from '../../../../../../constants'
import { BillingTerms } from './BillingTerms.component'
import { createErrorToast, createToast } from '../../../../../Toast'
import { updateUserSuccess } from '../../../../../../../actions/user'
import { FetchErrorInterface, StatusType } from '../../../../../../interfaces'
import { savePaymentMethod } from '../../../../helpers/savePaymentMethod.helper'
import { STRIPE_SETUP_INTENT_REDIRECT_PARAMS } from './BillingStripeRedirectModal.component'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../../../constants'

export function UpdatePaymentMethod({
  stripeSetupIntentClientSecret,
  onClose
}: {
  stripeSetupIntentClientSecret: string
  onClose: () => void
}) {
  const [savePaymentMethodStatus, setSavePaymentMethodStatus] = useState<StatusType>('idle')
  const [stripeElementStatus, setStripeElementStatus] = useState<'loading' | 'loader-init' | 'ready'>('loading')
  const [paymentMethodType, setPaymentMethodType] = useState('')

  const dispatch = useDispatch()
  const { mutateUser } = useUser()
  const stripe = useStripe()
  const elements = useElements()

  async function handleSave() {
    try {
      if (!stripe || !elements) {
        throw Error('Required data not found')
      }

      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
      setSavePaymentMethodStatus('loading')
      window.analytics.track('Started payment method setup', { type: paymentMethodType })

      const { error: submitError } = await elements.submit()
      if (submitError) {
        createToast({
          heading: 'Failed to update payment method',
          id: SETTINGS_UPDATE_ERROR_TOAST_ID,
          content: submitError.message,
          type: 'error'
        })
        setSavePaymentMethodStatus('error')
        window.analytics.track('Payment method setup error', { type: paymentMethodType })
        return
      }

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret: stripeSetupIntentClientSecret,
        confirmParams: {
          return_url:
            window.location.origin +
            ROUTE_PATHS.SETTINGS.BILLING +
            `?${STRIPE_SETUP_INTENT_REDIRECT_PARAMS.PM_TYPE}=${paymentMethodType}`
        },
        redirect: 'if_required'
      })

      if (error) {
        window.analytics.track('Payment method setup error', { type: paymentMethodType })
        createErrorToast({
          heading: 'Failed to update payment method',
          id: SETTINGS_UPDATE_ERROR_TOAST_ID,
          errorMessage: error.message
        })
        setSavePaymentMethodStatus('error')

        return
      }

      if (setupIntent) {
        await savePaymentMethod(setupIntent.payment_method)
        await mutateUser()

        // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
        dispatch(updateUserSuccess())
        createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })

        window.analytics.track('Payment method setup success', { type: paymentMethodType })
        setPaymentMethodType('')
        onClose()
      }
    } catch (error) {
      const errorResponse = error as { message?: string }
      const fetchErrorResponse = error as FetchErrorInterface<{ message?: string }>

      createErrorToast({
        heading: 'Failed to update payment method',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        errorMessage: fetchErrorResponse.responseBodyJson?.message ?? errorResponse.message,
        errorCode: `SI-RC-${fetchErrorResponse.status ?? '0'}`
      })
      window.analytics.track('Payment method setup error', { type: paymentMethodType })

      setPaymentMethodType('')
      onClose()
    }
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        handleSave()
      }}
    >
      {stripeElementStatus === 'loading' && <Skeleton className="h-[300px]" />}

      <PaymentElement
        onLoaderStart={() => setStripeElementStatus('loader-init')}
        onReady={() => setStripeElementStatus('ready')}
        onChange={(e) => setPaymentMethodType(e.value.type)}
      />

      <div className="mt-8 flex gap-4">
        <Button
          disabled={!stripe || !elements || stripeElementStatus !== 'ready'}
          isLoading={savePaymentMethodStatus === 'loading'}
          type="submit"
          variant="primary"
        >
          Save
        </Button>

        <Button disabled={savePaymentMethodStatus === 'loading'} type="button" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>

      <BillingTerms />

      {savePaymentMethodStatus === 'loading' && <OverlayPortal />}
    </form>
  )
}
