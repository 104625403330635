import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import Button from '../../../../../Button'
import { useUser } from '../../../../../../hooks'
import { fetcher } from '../../../../../../helpers'
import { StatusEnum } from '../../../../../../enums'
import OverlayPortal from '../../../../../OverlayPortal'
import ConfirmationModal from '../../../../../ConfirmationModal'
import { FetchErrorInterface } from '../../../../../../interfaces'
import { createErrorToast, createToast } from '../../../../../Toast'
import { updateUserSuccess } from '../../../../../../../actions/user'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../../../constants'

export function RemovePaymentMethodModalButton() {
  const { user, mutateUser } = useUser()
  const dispatch = useDispatch()

  const [isBillingRemoveModalOpen, setIsBillingRemoveModalOpen] = useState(false)
  const [removeBillingDetailsStatus, setRemoveBillingDetailsStatus] = useState(StatusEnum.Idle)

  if (!user) {
    throw Error('No user')
  }

  if (!user.billing.hasPaymentSetup) {
    return null
  }

  async function handleRemoveBillingDetails() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setRemoveBillingDetailsStatus(StatusEnum.Loading)

    try {
      await fetcher(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/removepayment`, { method: 'POST' })
      await mutateUser()
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      setRemoveBillingDetailsStatus(StatusEnum.Success)
      handleOpenChange(false)

      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string }>
      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.message,
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setRemoveBillingDetailsStatus(StatusEnum.Error)
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setRemoveBillingDetailsStatus(StatusEnum.Idle)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    }
    setIsBillingRemoveModalOpen(isOpen)
  }

  return (
    <>
      <Button
        className="inline-flex"
        variant="secondary"
        theme="danger"
        onClick={() => setIsBillingRemoveModalOpen(true)}
      >
        <span>
          Delete<span className="hidden md:inline"> payment details</span>
        </span>
      </Button>
      <ConfirmationModal
        closeButton={{ text: 'Cancel' }}
        continueButton={{ text: 'Remove', variant: 'primary', theme: 'danger' }}
        isLoading={removeBillingDetailsStatus === StatusEnum.Loading}
        open={isBillingRemoveModalOpen}
        setOpen={handleOpenChange}
        title="Remove billing details?"
        onContinue={handleRemoveBillingDetails}
        onCancel={() => handleOpenChange(false)}
      >
        <span>You won&apos;t be able to place an order until you add new billing details</span>
      </ConfirmationModal>
      {removeBillingDetailsStatus === StatusEnum.Loading && <OverlayPortal />}
    </>
  )
}
