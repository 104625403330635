import { useState } from 'react'
import toast from 'react-hot-toast'
import { format, formatDistance } from 'date-fns'

import { StatusEnum } from '../../../enums'
import { ProPlanBillingRadioButtons } from '.'
import { formatBillingCost } from '../helpers'
import ConfirmationModal from '../../ConfirmationModal'
import { PRO_PRO_FEATURE_SHORT_LIST } from '../constants'
import { ProBillingOption, SelectedPlan } from '../interfaces'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../Settings/constants'

export function ViewProSubscriptionDetailsModal({
  isModalOpen,
  proPlanBillingOptions,
  selectedProSubscriptionTier,
  userBillingPlan,
  openCancelProPlanModal,
  setIsModalOpen,
  setSelectedProSubscriptionTier
}: {
  isModalOpen: boolean
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedProSubscriptionTier: ProBillingOption
  userBillingPlan: SelectedPlan
  openCancelProPlanModal: () => void
  setIsModalOpen: (open: boolean) => void
  setSelectedProSubscriptionTier: React.Dispatch<React.SetStateAction<ProBillingOption>>
}) {
  const [updateBillingPlanStatus, setUpdateBillingPlanStatus] = useState(StatusEnum.Idle)

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    }
    setIsModalOpen(isOpen)
  }

  function switchBillingPlan() {
    console.log('Switch billing plan')
    handleOpenChange(false)
    setUpdateBillingPlanStatus(StatusEnum.Success)
    // TODO: add request to BE
    // handle failure
    // on success: dispatch(refreshAccessToken())
  }

  const isLoading = updateBillingPlanStatus === StatusEnum.Loading

  // DASHBOARD_PRODIGI_PRO - replace with real data
  const nextPaymentDate = new Date('2024-12-12T14:23:12.0000000Z')

  return (
    <ConfirmationModal
      className="relative max-w-[95vw] bg-white sm:min-w-[600px]"
      contentClassName="max-h-60vh overflow-auto pb-4 text-base max-w-4xl"
      continueButton={{
        variant: 'primary',
        text: 'Change payment plan',
        disabled: selectedProSubscriptionTier === userBillingPlan.subscriptionTier
      }}
      closeButton={{ variant: 'tertiary', theme: 'greyscale', text: 'Close' }}
      isLoading={isLoading}
      footer={
        <p className="mt-8 max-w-5xl text-gray-600">
          Changing your payment plan will be effective from when your existing billing period ends.{' '}
          <button className="text-purple-500 underline" onClick={openCancelProPlanModal}>
            Cancel at any time
          </button>{' '}
          online and your Pro subscription and benefits will stop at the end of the next paid period.
        </p>
      }
      open={isModalOpen}
      title="Your Pro account"
      setOpen={handleOpenChange}
      onCancel={() => handleOpenChange(false)}
      onContinue={switchBillingPlan}
    >
      <p className="font-medium">Pro features include:</p>
      <ul className="max-w-git mb-4 mt-2 list-inside list-disc lg:mb-8">
        {PRO_PRO_FEATURE_SHORT_LIST.map((feature) => (
          <li className="mt-2" key={feature}>
            {feature}
          </li>
        ))}
      </ul>

      <h3 className="m-0 mb-4 mt-16 font-medium">Payment</h3>
      <p>
        Your current billing period ends in{' '}
        <span className="font-medium">{formatDistance(new Date(), nextPaymentDate)}</span> days
      </p>

      <ProPlanBillingRadioButtons
        proPlanBillingOptions={proPlanBillingOptions}
        selectedSubscriptionTier={selectedProSubscriptionTier}
        setSelectedSubscriptionTier={setSelectedProSubscriptionTier}
      />

      <p className="mt-8">
        We will take your next payment of{' '}
        <span className="font-medium">{formatBillingCost(proPlanBillingOptions[selectedProSubscriptionTier])}</span> on{' '}
        <span className="font-medium">{format(nextPaymentDate, 'dd MMMM YYY')}</span>
      </p>
    </ConfirmationModal>
  )
}
