import { useDispatch, useSelector } from 'react-redux'

import { isNoImageProduct } from '../../../../v2/helpers'
import { buildImageId } from '../../../../actions/images'
import { getAllArtworks } from '../../../../selectors/images'
import { FormattedCsvRow, FormattedOrderItem } from '../../../../types'
import SelectProductModal from '../../../../v2/components/SelectProductModal'
import { selectDeliveryCountry } from '../../../../selectors/manualOrderForm'
import { ArtworkInterface } from '../../../../v2/components/ImageEditor/interfaces'
import { getCsvOrderByOrderItemId, getCsvOrderItem } from '../../../../selectors/csvUpload'
import { removeCsvItemArtwork, selectProductForCsvOrder } from '../../../../actions/csvUpload'
import { getProductFileCompatibilityData } from '../../../../v2/helpers/getProductFileCompatibilityData.helper'
import { ProductAdditionalDataInterface, SelectedProductInterface } from '../../../../v2/components/SelectProduct'

interface CsvProductSearchV2PropsInterface {
  orderId: string
  orderItemId: string
  sku: string
  defaultAttributes: Record<string, string>
  isModalOpen: boolean
  closeModal: () => void
  onError: (message: string) => void
  openImageModal: (orderItemId: string) => void
}

export function CsvProductSearchV2({
  orderId,
  orderItemId,
  sku,
  defaultAttributes,
  isModalOpen,
  closeModal,
  openImageModal
}: CsvProductSearchV2PropsInterface) {
  const dispatch = useDispatch()

  const mofSelectedDeliveryCountry: string = useSelector(selectDeliveryCountry)
  const csvOrder: FormattedCsvRow | undefined = useSelector((state) => getCsvOrderByOrderItemId(state, orderItemId))
  const csvOrderItem: FormattedOrderItem | undefined = useSelector((state) => getCsvOrderItem(state, orderItemId))
  const artworks: Record<string, ArtworkInterface | undefined> = useSelector((state) => getAllArtworks(state)) ?? {}

  const csvSelectedDeliveryCountry = csvOrder?.customer?.countryCode
  const defaultMetaData = csvOrderItem?.metaData
  const countryCode = csvSelectedDeliveryCountry || mofSelectedDeliveryCountry

  function handleSelectProduct(
    selectedProduct: SelectedProductInterface,
    { productDetails }: ProductAdditionalDataInterface
  ) {
    const productFileCompatibilityData = getProductFileCompatibilityData({
      productType: selectedProduct.searchResultData.productType
    })

    Object.keys(productDetails.product.printAreas).forEach((printArea) => {
      const imageId = buildImageId(csvOrderItem?.id ?? '', printArea)
      const artwork = artworks?.[imageId]

      const isImageFileType = artwork?.fileType === 'png' || artwork?.fileType === 'jpg'
      const isArtworkSupportedForItem =
        productFileCompatibilityData && artwork?.fileType
          ? productFileCompatibilityData.fileTypesAllowed.includes(artwork?.fileType)
          : isImageFileType

      if (!isArtworkSupportedForItem) {
        dispatch(removeCsvItemArtwork(orderItemId, printArea))
      }
    })

    dispatch(
      selectProductForCsvOrder(
        orderId,
        orderItemId,
        selectedProduct.searchResultData,
        selectedProduct.selectedAttributes || {},
        selectedProduct.selectedMetaData
      )
    )
    closeModal()

    if (!isNoImageProduct(selectedProduct.searchResultData.productType)) {
      openImageModal(orderItemId)
    }
  }

  return (
    <SelectProductModal
      countryCode={countryCode}
      defaultSearchQuery={sku}
      defaultSelectedAttributes={defaultAttributes}
      defaultSelectedMetaData={defaultMetaData}
      showMetaData={true}
      open={isModalOpen}
      setOpen={(isOpen) => {
        if (!isOpen) {
          closeModal()
        }
      }}
      onSelectProductWithAdditionalData={handleSelectProduct}
    />
  )
}
