// @flow
import {
  FINISH_IMAGE_UPLOAD_FROM_URL,
  uploadImageFromFile,
  FINISH_IMAGE_UPLOAD_FROM_FILE,
  initArtworkTransformations,
  updateArtwork,
  updateArtworkTransformations,
  buildImageId
} from '../images'
import type {
  ArtworkTransformations,
  Artwork,
  DispatchFunc,
  GetStateFunc,
  FormattedOrderItem,
  Dictionary,
  MultiAssetTemplates,
  ImageSizing,
  Dimensions,
  MultiAssetTemplate,
  ThunkAsync,
  Thunk,
  CatalogueItem
} from '../../types'
import type { UpdateCsvItemUrl } from '../types'
import {
  getCsvItemTemplates,
  getCsvItemSku,
  getCsvOrderItemsByItemIds,
  selectCsvOrderItems,
  selectProductDetails
} from '../../selectors/csvUpload'
import { getArtworkByItemId } from '../../selectors/images'
import { values, entries } from '../../helpers/dictionary'
import { calcScaleFactorAdjustment, convertPrintPxPositionToUserUnit, buildPigUrl } from '../../helpers/imageEditor'
import { SCALE_TYPE } from '@prodigi-group/components-image-editor'
import { sleep } from '../../helpers/promise'
import { IMAGE_SIZING } from '../../data/imageSizing'
import { finishSelectImageLibraryImage } from '../images/finishSelectImageLibraryImage'
import type { UploadImageFromUrlResult } from '../types/images/uploadImageFromUrl'
import { FAILED_IMAGE_UPLOAD_FROM_URL, START_IMAGE_UPLOAD_FROM_URL } from '../images/uploadImageFromUrl'
import type { ImageLibraryImageType } from '../types/images/images'
import { getCsvOrderByOrderItemId, getCsvOrderItems, getProductBySku } from '../../selectors/csvUpload/csvUpload'
import { fetchPricingAndShippingForCsvOrder } from './fetchPricingAndShippingForCsvOrder'
import { removeCsvItemArtwork } from './removeCsvItemArtwork'

// $FlowFixMe: TypeScript file
import { uploadURLToImageLibrary } from 'src/v2/helpers/uploadURLToImageLibrary.helper'
// $FlowFixMe: TypeScript file
import { getProductFileCompatibilityData, requestImageLibraryThumbnailGeneration } from 'src/v2/helpers'
// $FlowFixMe: TypeScript file
import { transformImage } from '../../v2/helpers'

export const UPDATE_CSV_ITEM_URL = 'UPDATE_CSV_ITEM_URL'

type UploadCsvArtworkFromUrl = {|
  url: string,
  itemIds: string[],
  isTilingEnabled: boolean,
  allowCustomSizing?: boolean
|}

export function uploadCsvArtworkFromUrl({
  url,
  itemIds,
  isTilingEnabled,
  allowCustomSizing
}: UploadCsvArtworkFromUrl): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const orderItems = selectCsvOrderItems(getState())

    itemIds.forEach((itemId) => dispatch(updateCsvItemUrl(url, itemId, orderItems[itemId]?.selectedPrintArea, url)))

    const imageIds = itemIds.map((itemId) => buildImageId(itemId, orderItems[itemId]?.selectedPrintArea))

    const result = await dispatch(uploadCsvImageURLtoImageLibrary(url, imageIds))

    if (result.type === FINISH_IMAGE_UPLOAD_FROM_URL) {
      const orderItems = getCsvOrderItemsByItemIds(getState(), itemIds)

      itemIds.forEach((itemId) => {
        const sizing = allowCustomSizing && orderItems[itemId] ? orderItems[itemId].sizing : IMAGE_SIZING.FILL

        if (!orderItems[itemId]?.selectedPrintArea) {
          console.warn(`selectedPrintArea cannot be null, itemId: ${itemId}`)
          return
        }

        const selectedPrintArea = orderItems[itemId]?.selectedPrintArea
        const imageId = buildImageId(itemId, selectedPrintArea)

        dispatch(onSuccessfulUpload(result.payload, itemId, imageId, isTilingEnabled, selectedPrintArea, sizing))
      })
    }
  }
}

function wait(ms: number = 1000): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

async function poll<T>(
  fetchData: () => Promise<T>,
  shouldContinuePolling: (result: T) => boolean,
  pollingInterval: number
): Promise<T> {
  let result = await fetchData()
  while (shouldContinuePolling(result)) {
    await wait(pollingInterval)
    result = await fetchData()
  }
  return result
}

function uploadCsvImageURLtoImageLibrary(url: string, imageIds: string[]): ThunkAsync<UploadImageFromUrlResult> {
  return async (dispatch: DispatchFunc): Promise<UploadImageFromUrlResult> => {
    dispatch({
      type: START_IMAGE_UPLOAD_FROM_URL,
      meta: { ids: imageIds }
    })

    try {
      let imageLibraryImage = null
      imageLibraryImage = await uploadURLToImageLibrary({ url, shouldSaveToLibrary: false })

      if (imageLibraryImage?.upload_status !== 'success') {
        imageLibraryImage = await poll<ImageLibraryImageType>(
          () => requestImageLibraryThumbnailGeneration(imageLibraryImage?.public_id),
          (imageLibraryImage) => imageLibraryImage.upload_status !== 'success',
          5000
        )
      }

      if (
        imageLibraryImage?.upload_status !== 'success' ||
        !imageLibraryImage?.pixel_height ||
        !imageLibraryImage?.url ||
        !imageLibraryImage?.pixel_width ||
        !imageLibraryImage?.thumbnail_urls.small ||
        !imageLibraryImage?.thumbnail_urls.large
      ) {
        throw Error(
          `${imageLibraryImage?.public_id ?? '0'}-${imageLibraryImage?.upload_status ?? '0'}-${
            imageLibraryImage?.mime_type ?? '0'
          }`
        )
      }
      const smallImageUrl = imageLibraryImage.thumbnail_urls.small
      const mediumImageUrl = imageLibraryImage.thumbnail_urls.large

      return dispatch({
        type: FINISH_IMAGE_UPLOAD_FROM_URL,
        payload: {
          croppedImageUrl: imageLibraryImage.url,
          smallImageUrl,
          mediumImageUrl,
          pageCount: imageLibraryImage.pdf_page_count,
          originalImageUrl: imageLibraryImage.url,
          artworkHeight: imageLibraryImage.pixel_height,
          artworkWidth: imageLibraryImage.pixel_width,
          imageLibraryId: imageLibraryImage.public_id,
          transformImageUrl: imageLibraryImage.url,
          fileType: imageLibraryImage.file_type,
          mimeType: imageLibraryImage.mime_type
        },
        meta: { ids: imageIds }
      })
    } catch (error) {
      return dispatch({
        type: FAILED_IMAGE_UPLOAD_FROM_URL,
        meta: { ids: imageIds },
        payload: {
          errorMessage: `Failed to upload image, please try re-uploading manually (${
            error?.responseBodyJson?.detail ?? error?.message ?? error?.status?.toString() ?? '0'
          })`,
          debugMessage: JSON.stringify(error)
        }
      })
    }
  }
}

export function uploadCsvArtworkFromFile(
  file: File,
  itemId: string,
  isTilingEnabled: boolean,
  printAreaName: string
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const imageId = buildImageId(itemId, printAreaName)
    const result = await dispatch(uploadImageFromFile({ file, id: imageId }))

    if (result.type === FINISH_IMAGE_UPLOAD_FROM_FILE) {
      dispatch(onSuccessfulUpload(result.payload, itemId, imageId, isTilingEnabled, printAreaName))
    }
  }
}

export function onSelectCsvImageLibraryImage({
  artwork,
  itemId,
  isTilingEnabled,
  printAreaName
}: {|
  artwork: Artwork,
  itemId: string,
  isTilingEnabled: boolean,
  printAreaName: string
|}): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const imageId = buildImageId(itemId, printAreaName)
    dispatch(finishSelectImageLibraryImage({ artwork, imageId }))
    dispatch(onSuccessfulUpload(artwork, itemId, imageId, isTilingEnabled, printAreaName))
  }
}

export function updateCsvItemArtwork(
  transformations: ArtworkTransformations,
  pigUrl: string,
  id: string,
  printAreaName: string,
  transformImageUrl: string
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const imageId = buildImageId(id, printAreaName)

    await dispatch(updateArtwork(pigUrl, imageId, transformImageUrl))

    const artwork = getArtworkByItemId(getState(), imageId)

    if (artwork) {
      dispatch(updateArtworkTransformations(transformations, imageId))
      dispatch(updateCsvItemUrl(artwork.croppedImageUrl, id, printAreaName, transformImageUrl))
    }
  }
}

function onSuccessfulUpload(
  artwork: Artwork,
  itemId: string,
  imageId: string,
  isTilingEnabled: boolean,
  printAreaName: string,
  sizing?: ImageSizing = IMAGE_SIZING.FILL
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const templates = getCsvItemTemplates(getState(), itemId)
    const sku = getCsvItemSku(getState(), itemId)
    const orderItems = getCsvOrderItems(getState())
    const order = getCsvOrderByOrderItemId(getState(), itemId)

    const catalogueDetails = getProductBySku(getState(), orderItems[itemId].sku)
    const productFileCompatibilityData = getProductFileCompatibilityData({
      productType: catalogueDetails?.productType
    })

    const isImageFileType = artwork.fileType === 'png' || artwork.fileType === 'jpg'
    const isArtworkSupportedForItem = productFileCompatibilityData
      ? productFileCompatibilityData.fileTypesAllowed.includes(artwork.fileType)
      : isImageFileType

    if (!isArtworkSupportedForItem || !catalogueDetails) {
      dispatch(removeCsvItemArtwork(itemId, printAreaName))
      return
    }

    if (!sku) {
      console.warn('SKU is null. Item ID:', itemId)
      return
    }

    if (templates) {
      const nonNullTemplates = templates
      const actionResult = await dispatch(
        initArtworkTransformations({ artwork, templates: nonNullTemplates, itemId: imageId, sizing, printAreaName })
      )

      if (sizing === IMAGE_SIZING.FIT) {
        dispatch(
          updateArtworkToFitPrintArea(artwork, actionResult.transformations, nonNullTemplates, itemId, printAreaName)
        )
      }
    }

    dispatch(
      updateCsvItemUrl(
        artwork.originalImageUrl,
        itemId,
        printAreaName,
        artwork.transformImageUrl ?? artwork.originalImageUrl
      )
    )

    const shouldFetchQuoteForUploadedArtwork = artwork?.pageCount && artwork.pageCount > 0

    if (shouldFetchQuoteForUploadedArtwork && order) {
      dispatch(fetchPricingAndShippingForCsvOrder(order?.id))
    }
  }
}

function updateArtworkToFitPrintArea(
  artwork: Artwork,
  transformations: ArtworkTransformations,
  templates: MultiAssetTemplates,
  itemId: string,
  printAreaName: string
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc) => {
    const template = templates.printAreas[printAreaName]?.orientations[transformations.orientation]

    if (!template) {
      return
    }

    const artworkDimensionsInPx = {
      width: artwork.artworkWidth,
      height: artwork.artworkHeight
    }

    try {
      const transformedImage = await transformImage({
        artwork,
        imageLibraryId: artwork.imageLibraryId,
        template,
        transformations,
        preview: false
      })

      dispatch(
        updateCsvItemArtworkAndPigUrl(
          artwork.originalImageUrl,
          artworkDimensionsInPx,
          transformations,
          template,
          itemId,
          printAreaName,
          transformedImage.url
        )
      )
    } catch (error) {
      console.error(error)
    }
  }
}

function updateCsvItemArtworkAndPigUrl(
  originalImageUrl: string,
  artworkDimensionsInPx: Dimensions,
  transformations: ArtworkTransformations,
  template: MultiAssetTemplate,
  itemId: string,
  printAreaName: string,
  transformImageUrl: string
): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    const scaleFactorAdjustment = calcScaleFactorAdjustment(
      template.printResolution,
      artworkDimensionsInPx,
      template.cropRectangle,
      template.printDpi,
      transformations.borderFactor,
      transformations.borderScale
    )
    const scaleFactorInPigFriendlyFormat = (transformations.scaleFactor * scaleFactorAdjustment) / 100
    const pigPosition = convertPrintPxPositionToUserUnit(
      transformations.position,
      transformations.borderScale,
      template.printResolution,
      artworkDimensionsInPx,
      template.cropRectangle,
      template.printDpi
    )

    const imageEditorTransformations = {
      scaleFactor: scaleFactorInPigFriendlyFormat,
      scaleType: SCALE_TYPE.PRINT_SIZE,
      position: pigPosition,
      positionUnit: transformations.borderScale,
      rotationAngle: transformations.rotationAngle,
      borderWidth: transformations.borderFactor,
      borderUnit: transformations.borderScale,
      isTiled: transformations.isTiled
    }

    const pigUrl = buildPigUrl(
      originalImageUrl,
      imageEditorTransformations,
      artworkDimensionsInPx,
      template.printResolution,
      template.cropRectangle,
      template.printDpi
    )

    dispatch(updateCsvItemArtwork(transformations, pigUrl, itemId, printAreaName, transformImageUrl))
  }
}

export function updateCsvItemUrl(
  url: string,
  itemId: string,
  printArea?: ?string,
  transformImageUrl: string
): UpdateCsvItemUrl {
  return {
    type: UPDATE_CSV_ITEM_URL,
    itemId,
    url,
    printArea,
    transformImageUrl
  }
}

export function uploadArtworkUsingCsvUrls(orderItems: Dictionary<FormattedOrderItem>): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const catalogueItems = selectProductDetails(getState())
    const orderItemIdsGroupedByImageUrls = groupOrderItemIdsByImageUrls(orderItems, catalogueItems)

    for (const [imageUrl, orderItemIds] of entries(orderItemIdsGroupedByImageUrls)) {
      dispatch(uploadArtworkForCsvItem(imageUrl, orderItemIds))
      await sleep(250)
    }
  }
}

function groupOrderItemIdsByImageUrls(
  orderItems: Dictionary<FormattedOrderItem>,
  catalogueDetails: Dictionary<CatalogueItem>
): Dictionary<string[]> {
  // Grouping identical imageUrls in the CSV so we only upload them once
  // At this point we don't care about the printAreas - will need to be taken into account
  // with proper multi asset support in CSV headers
  return values(orderItems).reduce((urlAndIdAcc, orderItem) => {
    const itemProductDetails = catalogueDetails[orderItem?.sku ?? ''] ?? null
    if (!orderItem.unknownPrintAreaImageUrl || itemProductDetails?.noImageProduct) {
      return urlAndIdAcc
    }

    if (!urlAndIdAcc[orderItem.unknownPrintAreaImageUrl]) {
      urlAndIdAcc[orderItem.unknownPrintAreaImageUrl] = [orderItem.id]
    } else {
      urlAndIdAcc[orderItem.unknownPrintAreaImageUrl] = [
        ...urlAndIdAcc[orderItem.unknownPrintAreaImageUrl],
        orderItem.id
      ]
    }

    return urlAndIdAcc
  }, {})
}

export function uploadArtworkForCsvItem(url: string, itemIds: string[]): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(
      uploadCsvArtworkFromUrl({
        url,
        itemIds,
        isTilingEnabled: false,
        allowCustomSizing: true
      })
    )
  }
}
