import { DictionaryInterface, OrderDetailItemInterface } from '../../../interfaces'
import { VariantSelectArtworkDataInterface } from '../../../hooks/useSalesChannelProduct.hook'
import { VariantDataInterface } from '../../SalesChannelProduct/SalesChannelProduct.component'

export function mapLineItemToVariantData(lineItem: OrderDetailItemInterface, price?: string) {
  const assetRequirementData: Record<string, { required: boolean }> = {}
  const mappedArtwork = lineItem.assets.reduce(
    (artworkAcc: DictionaryInterface<VariantSelectArtworkDataInterface>, asset) => {
      assetRequirementData[asset.printAreaName] = { required: asset.required }

      if (!asset.additionalUiDetail) {
        return artworkAcc
      }

      const artworkDetails = asset.additionalUiDetail

      const mappedArtworkData: VariantSelectArtworkDataInterface = {
        previewImageUrl: artworkDetails.previewImageUrl,
        printImageUrl: artworkDetails.printImageUrl,
        artworkTransformations: undefined,
        artwork: undefined,
        transformImageUrl: artworkDetails.transformImageUrl
      }

      if (artworkDetails.artworkTransformations) {
        mappedArtworkData.artworkTransformations = {
          scaleFactor: artworkDetails.artworkTransformations.scaleFactor,
          orientation: artworkDetails.artworkTransformations.orientation,
          position: artworkDetails.artworkTransformations.position,
          rotationAngle: artworkDetails.artworkTransformations.rotationAngle,
          borderFactor: artworkDetails.artworkTransformations.borderFactor,
          borderScale: artworkDetails.artworkTransformations.borderScale,
          isTiled: artworkDetails.artworkTransformations.isTiled
        }
      }

      if (artworkDetails.artwork) {
        mappedArtworkData.artwork = {
          smallImageUrl: artworkDetails.artwork.smallImageUrl,
          mediumImageUrl: artworkDetails.artwork.mediumImageUrl,
          originalImageUrl: artworkDetails.artwork.originalImageUrl,
          artworkHeight: artworkDetails.artwork.artworkHeight,
          artworkWidth: artworkDetails.artwork.artworkWidth,
          imageLibraryId: artworkDetails.artwork.imageLibraryId,
          mimeType: artworkDetails.artwork.mimeType,
          pageCount: artworkDetails.artwork.pageCount,
          fileType: artworkDetails.artwork.fileType
        }
      }

      artworkAcc[asset.printAreaName] = mappedArtworkData
      return artworkAcc
    },
    {}
  )

  const mappedVariantData: Omit<VariantDataInterface, 'title'> = {
    id: lineItem.salesChannelProductDetails.variantId,
    isAutoFulfilled: lineItem.isReadyForFulfilment,
    isFulfilled: true,
    selectedProductData: {
      attributes: Object.keys(lineItem.attributes).length > 0 ? lineItem.attributes : null,
      sku: lineItem.prodigiSku,
      productType: lineItem.productType,
      price
    },
    assetRequirementData: assetRequirementData,
    selectedPrintArea: lineItem.assets[0].printAreaName,
    artworkData: Object.keys(mappedArtwork).length > 0 ? mappedArtwork : undefined,
    preferredShippingMethod: null
  }

  return mappedVariantData
}
