import React from 'react'

import FormItem from '../FormItem'
import Skeleton from '../Skeleton'
import { useCountries } from '../../hooks'
import { usePricingInformation } from './hooks'
import { PricingHeader, DownloadPricingDisplay } from './components'

// DASHBOARD_APC - when this is removed from the code:
// showTitle can be removed and the h2 in PricingHeader can be removed
export default function DownloadPricing({ showTitle = true }: { showTitle?: boolean }) {
  const { pricingInformation, isLoading, error } = usePricingInformation()
  const { countries, isLoading: loadingCountries, error: countriesError } = useCountries()

  if (
    error ||
    (pricingInformation && Object.keys(pricingInformation?.payload).length === 0) ||
    countriesError ||
    (countries && Object.keys(countries).length === 0)
  ) {
    return (
      <DownloadPricingContainer showTitle={showTitle}>
        <DownloadPricingError />
      </DownloadPricingContainer>
    )
  }

  if (isLoading || !pricingInformation || loadingCountries || !countries) {
    return (
      <DownloadPricingContainer showTitle={showTitle}>
        <DownloadPricingLoading />
      </DownloadPricingContainer>
    )
  }

  return (
    <DownloadPricingContainer showTitle={showTitle}>
      <DownloadPricingDisplay pricingInformation={pricingInformation} countries={countries} />
    </DownloadPricingContainer>
  )
}

function DownloadPricingContainer({ children, showTitle }: { children: React.ReactNode; showTitle: boolean }) {
  return (
    <div className="w-full gap-2 border bg-white p-8 md:gap-4">
      <PricingHeader showTitle={showTitle} />
      {children}
    </div>
  )
}

function DownloadPricingLoading() {
  return (
    <>
      <div className="grid grid-cols-1 pt-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-4 md:gap-12 md:pt-12">
        <FormItem labelText="Category" inputField={<Skeleton className="mt-1 h-[40px] w-full" />} />
        <FormItem labelText="Subcategory" inputField={<Skeleton className="mt-1 h-[40px] w-full" />} />
        <div>
          <FormItem labelText="Shipping to" inputField={<Skeleton className="mt-1 h-[40px] w-full" />} />
          <Skeleton className="mt-2 w-48" />
        </div>
      </div>

      <Skeleton className="mt-8 h-[40px] w-48" />

      <div className="mt-4 flex flex-row space-x-4 md:mt-6">
        <p className="text-sm text-gray-600">Last updated</p>
        <Skeleton className="w-32" />
      </div>
    </>
  )
}

function DownloadPricingError() {
  return (
    <>
      <p className="pt-8 text-red-500">An unexpected error occured. Please try again later</p>
    </>
  )
}
