import { areCompanyDetailsValid } from '../../helpers'
import { PaymentMethod } from './components/PaymentMethod'
import { useSplitToggle, useUser } from '../../../../hooks'
import { SettingsHeader } from '../SettingsHeader.component'
import { IncompleteCompanyDetailsPanel } from './components'
import { SettingsContainer } from '../SettingsContainer.component'
import { FEATURE_NAMES } from '../../../../../split-io/feature-names'
import BillingSubscriptionPlans from '../../../BillingSubscriptionPlans'
import { BillingInformationFormPanel } from './components/BillingInformationForm'

export function SettingsBilling() {
  const { splitIsOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_PRODIGI_PRO })
  const { user } = useUser()

  if (!user) {
    throw Error('No user')
  }

  const userHasFilledCompanyDetails = areCompanyDetailsValid({
    companyName: user.company.name,
    addressLine1: user.company.address1,
    city: user.company.townCity,
    postCode: user.company.postcode,
    county: user.company.countyState,
    countryCode: user.company.countryCode
  })

  return (
    <SettingsContainer>
      <SettingsHeader />
      {splitIsOn && <BillingSubscriptionPlans />}
      <BillingInformationFormPanel />
      {userHasFilledCompanyDetails ? <PaymentMethod /> : <IncompleteCompanyDetailsPanel />}
    </SettingsContainer>
  )
}
