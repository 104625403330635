import pako from 'pako'
import useSWR from 'swr'

import { PricingInformationInterface } from '../interfaces'
import { FetchErrorInterface } from '../../../interfaces'
import { PRICING_INFO_STORAGE_BASE_URL, PRICING_INFO_STORAGE_DATE } from '../constants'

type PricingInformationErrorType = unknown

export function usePricingInformation() {
  const pricingInformationUrl = PRICING_INFO_STORAGE_BASE_URL + '/' + PRICING_INFO_STORAGE_DATE + '/manifest.json.gz'

  const { data, error } = useSWR<PricingInformationInterface, FetchErrorInterface<PricingInformationErrorType>>(
    pricingInformationUrl,
    fetchGZipFile
  )

  return {
    pricingInformation: data,
    isLoading: !data && !error,
    error
  }
}

class FetchError<T> extends Error {
  status

  constructor({ message, name, status }: FetchErrorInterface<T>) {
    super(message)
    this.name = name
    this.message = message
    this.status = status
  }
}

async function fetchGZipFile<T>(url: string): Promise<T> {
  return fetch(url).then(async (response) => {
    const responseBlob = await response.blob()
    const arrayBuffer = await responseBlob.arrayBuffer()
    const dataToUnzip = new Uint8Array(arrayBuffer)
    const unzippedData = pako.ungzip(dataToUnzip, { to: 'string' })
    const dataAsJson = JSON.parse(unzippedData)

    if (!response.ok) {
      throw new FetchError({
        message: response.statusText,
        name: 'FETCH_ERROR',
        status: response.status
      })
    }

    return dataAsJson
  })
}
