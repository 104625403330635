import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { useSplitToggle } from '../../../hooks'
import { RecommendedProductInterface } from '../interfaces'
import { RECOMMENDED_PRODUCTS_CATALOGUE } from '../constants'
import { clearProductSearch } from '../../../../actions/catalogue'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { CATALOGUE_PRODUCTS, QUERY_PARAMS, ROUTE_PATHS } from 'src/v2/constants'

export function RecommendedProducts() {
  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  return (
    <div className="w-full pb-28">
      <div className="container mx-auto">
        <h2 className="mt-8 mb-5 text-black">Recommended products</h2>

        <p className="mb-16">Set your store up for success with our most-sold products.</p>

        <ul className="grid list-none grid-cols-2 gap-16 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
          {isAPCSplitOn ? (
            <>
              {APC_RECOMMENDED_PRODUCTS.map((apcRecommendedProduct) => (
                <ApcRecommendedProduct
                  key={apcRecommendedProduct.imageUrl}
                  apcRecommendedProduct={apcRecommendedProduct}
                />
              ))}
            </>
          ) : (
            <>
              {RECOMMENDED_PRODUCTS_CATALOGUE.map((details) => (
                <Product key={details.category + details.productType} details={details} />
              ))}
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

function Product({ details }: { details: RecommendedProductInterface }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const detailImage = details.image
  const categoryImage = CATALOGUE_PRODUCTS[details.category]?.image
  const productTypeImage = details.productType
    ? CATALOGUE_PRODUCTS[details.category]?.productTypes[details.productType]?.image
    : null

  function handleRecommendedProductClick() {
    window.analytics.track('Clicked Recommended Product', {
      productName: details.label
    })
    const categoryData = CATALOGUE_PRODUCTS[details.category]
    const isCategoryOnly = categoryData && Object.keys(categoryData.productTypes).length === 0

    const hasCategoryAndProductType =
      categoryData && details.productType && categoryData.productTypes[details.productType]

    if (hasCategoryAndProductType && details.productType) {
      dispatch(clearProductSearch())
      history.push(
        `${ROUTE_PATHS.MOF.SEARCH}?${QUERY_PARAMS.MOF.SEARCH.CATEGORY}=${encodeURIComponent(details.category)}&${
          QUERY_PARAMS.MOF.SEARCH.PRODUCT_TYPE
        }=${encodeURIComponent(details.productType)}`
      )
      return
    }

    if (isCategoryOnly) {
      dispatch(clearProductSearch())
      history.push(
        `${ROUTE_PATHS.MOF.SEARCH}?${QUERY_PARAMS.MOF.SEARCH.CATEGORY}=${encodeURIComponent(details.category)}`
      )
      return
    }

    history.push(`${ROUTE_PATHS.PRODUCTS}/${encodeURIComponent(details.category)}`)
  }

  const noImageFound = !detailImage && !productTypeImage && !categoryImage
  if (noImageFound) {
    return null
  }

  return (
    <motion.li whileHover="hover" className="w-full" key={details.category}>
      <button
        className="flex w-full cursor-pointer flex-col items-center gap-2"
        onClick={handleRecommendedProductClick}
      >
        <div className="aspect-square w-full overflow-hidden rounded border border-gray-200">
          <motion.img
            variants={{
              hover: {
                scale: 1.05
              }
            }}
            alt={details.label}
            src={detailImage || productTypeImage || categoryImage}
            className="w-full object-contain object-center"
          />
        </div>

        <span className="text-black">{details.label}</span>
      </button>
    </motion.li>
  )
}

type ApcRecommendedProductType = {
  name: string
  url: string
  imageUrl: string
}

const APC_RECOMMENDED_PRODUCTS: ApcRecommendedProductType[] = [
  {
    name: 'Acrylic prisms',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/home-and-living/tabletops/acrylic-prism`,
    imageUrl: '/img/v2/recommended-products/acrylic-prism.webp'
  },
  {
    name: 'Classic frames',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/wall-art/framed-prints/classic-frames`,
    imageUrl: '/img/v2/recommended-products/classic-frames.webp'
  },
  {
    name: 'Cushions',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/home-and-living/cushions-and-pillows/cushions`,
    imageUrl: '/img/v2/recommended-products/cushions.webp'
  },
  {
    name: 'Fine art prints',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/prints-and-posters/art-prints/enhanced-matte-art`,
    imageUrl: '/img/v2/recommended-products/enhanced-matte-art.webp'
  },
  {
    name: 'Framed canvas',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/wall-art/canvas/framed-canvas/framed-canvas`,
    imageUrl: '/img/v2/recommended-products/classic-framed-canvas.webp'
  },
  {
    name: 'Photo mugs',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/home-and-living/drinkware/photo-mugs`,
    imageUrl: '/img/v2/recommended-products/photo-mug.webp'
  },
  {
    name: 'Tough phone cases',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/technology/phone-cases/tough-phone-case`,
    imageUrl: '/img/v2/recommended-products/tough-case.webp'
  },
  {
    name: 'Stretched canvas',
    url: `${ROUTE_PATHS.PRODUCT_CATALOGUE}/wall-art/canvas/stretched-canvas/stretched-canvas`,
    imageUrl: '/img/v2/recommended-products/stretched-canvas.webp'
  }
]

function ApcRecommendedProduct({ apcRecommendedProduct }: { apcRecommendedProduct: ApcRecommendedProductType }) {
  const { name, url, imageUrl } = apcRecommendedProduct

  return (
    <motion.li whileHover="hover" className="w-full">
      <Link
        className="flex w-full cursor-pointer flex-col items-center gap-2"
        to={url}
        onClick={() =>
          window.analytics.track('Clicked Recommended Product', {
            productName: name
          })
        }
      >
        <div className="aspect-square w-full overflow-hidden">
          <motion.img
            variants={{
              hover: {
                scale: 1.05
              }
            }}
            alt={name}
            src={imageUrl}
            className="w-full object-contain object-center"
          />
        </div>

        <span className="text-center text-black">{name}</span>
      </Link>
    </motion.li>
  )
}
