// @flow
import React, { PureComponent } from 'react'
import type { FormattedOrderItem } from '../../../../types'
import cx from 'classnames'
import { getFormattedAttribute } from '../../../../helpers/csvUpload'
import CsvOpenModalButton from '../CsvOpenModalButton'
import NumberField from '../../../../components/inputs/NumberField'
import styles from './CsvItemProductView.module.css'
import LoadingEllipsis from '../../../../components/LoadingEllipsis'

// $FlowFixMe: TypeScript file
import { CsvItemMetaDataView } from './CsvItemMetaDataView.component'

type Props = {|
  item: FormattedOrderItem,
  canBeEdited: boolean,
  isProductComplete: boolean,
  productCategory: string,
  isLoading: boolean,
  selectedAttributes: [string, string][],
  productDescription: ?string,
  onQuantityChange: (e: SyntheticEvent<*>) => void,
  openProductSearchModal: () => void
|}

export default class CsvItemProductView extends PureComponent<Props> {
  render(): React$Node {
    const { productDescription, item, canBeEdited, productCategory, selectedAttributes, isLoading } = this.props
    return (
      <React.Fragment>
        {this.props.isProductComplete && (
          <React.Fragment>
            <span className={styles.sku}>{item.sku}</span>

            {productDescription && <span className={cx(styles.item, styles.productName)}>{productDescription}</span>}

            {selectedAttributes.map(([attributeName, attributeValue]) => {
              const { name, value } = getFormattedAttribute(attributeName, attributeValue, productCategory)
              return (
                <span className={styles.attribute} key={attributeName}>
                  <span>{name}</span>: <span>{value}</span>
                </span>
              )
            })}

            <CsvItemMetaDataView metaData={item.metaData} />

            <div className={styles.quantityContainer}>
              <span className={styles.quantity}>Quantity:</span>
              <NumberField
                disabled={!canBeEdited}
                className={styles.quantityInput}
                onChange={this.props.onQuantityChange}
                id={item.id}
                tabIndex="1"
                min="1"
                defaultValue={item.quantity}
                dataTest="item-quantity"
              />
            </div>
          </React.Fragment>
        )}
        {isLoading ? (
          <LoadingEllipsis className={styles.loadingEllipsis} />
        ) : (
          canBeEdited && (
            <div className={styles.action}>
              <CsvOpenModalButton
                onClick={this.props.openProductSearchModal}
                isCompleted={this.props.isProductComplete}
                dataTest={this.props.isProductComplete ? 'change-product-button' : 'select-product-button'}
                text={this.props.isProductComplete ? 'Change Product' : 'Select Product'}
              />
            </div>
          )
        )}
      </React.Fragment>
    )
  }
}
