import { cn } from '../../helpers'

interface SelectFieldPropsInterface
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  dataTest?: string
  uppercase?: boolean
}

export function SelectField({
  autoCapitalize = 'off',
  autoComplete = 'off',
  className = '',
  dataTest,
  disabled,
  uppercase,
  children,
  ...props
}: SelectFieldPropsInterface) {
  return (
    <select
      autoComplete={autoComplete}
      autoCapitalize={autoCapitalize}
      className={cn(
        'mt-2 h-16 w-full cursor-pointer appearance-none self-center truncate rounded-md border border-gray-300 bg-[url("../../public/img/icons/arrow-down.svg")] bg-[right_1em_center] bg-no-repeat pl-3 pr-14 text-input text-black outline-none focus:border-gray-700 disabled:cursor-not-allowed sm:text-base',
        uppercase ? 'uppercase' : '',
        className
      )}
      style={{ backgroundSize: '0.75em 0.75em', backgroundColor: disabled ? '#f5f5f5' : '#ffffff' }}
      data-test={dataTest}
      disabled={disabled}
      {...props}
    >
      {children}
    </select>
  )
}
