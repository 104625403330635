// @flow
import type { Dictionary, FormattedOrderItem } from '../../types'
import type { BasketItemMetaDataType } from '../../types/basketItem'

import { values } from '../../helpers/dictionary'

export function updateOrderItemProductDetails(
  orderItems: Dictionary<FormattedOrderItem>,
  idsOfItemsToUpdate: string[],
  sku: string,
  selectedAttributes: Dictionary<string>,
  metaData?: BasketItemMetaDataType
): Dictionary<FormattedOrderItem> {
  return values(orderItems).reduce((orderItemAcc, orderItem) => {
    const shouldUpdateItem = idsOfItemsToUpdate.includes(orderItem.id)

    if (shouldUpdateItem) {
      orderItemAcc[orderItem.id] = {
        ...orderItem,
        sku: sku,
        selectedAttributes: selectedAttributes,
        metaData: metaData
      }
    } else {
      orderItemAcc[orderItem.id] = orderItem
    }

    return orderItemAcc
  }, {})
}
