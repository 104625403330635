import useSWR, { SWRConfiguration } from 'swr'
import {
  azureSearchFetcher,
  getProductSearchUrl,
  mapAzureSearchResultsToProduct,
  sortAndFilterSearchResults
} from '../helpers'
import { AzureSearchResultInterface, FetchErrorInterface } from '../interfaces'
import { selectAzureSearchSettings } from '../selectors/appSettings'

type SearchSuccessType = { value: AzureSearchResultInterface[] }

type SearchErrorType = unknown

export function useProductSearch({
  config,
  countryCode,
  productType = '',
  category = '',
  query = ''
}: {
  config?: SWRConfiguration
  countryCode?: string
  productType?: string | null
  category?: string | null
  query?: string
}) {
  const url =
    countryCode && Boolean(query || category)
      ? getProductSearchUrl(query, selectAzureSearchSettings().endpoint, countryCode, productType ?? '', category ?? '')
      : null

  const { data, error } = useSWR<SearchSuccessType, FetchErrorInterface<SearchErrorType>>(
    url,
    azureSearchFetcher,
    config
  )
  const sortedResults = data && sortAndFilterSearchResults(data.value, query)
  const searchResults = sortedResults && mapAzureSearchResultsToProduct(sortedResults)

  return {
    searchResults,
    isLoading: Boolean(url && !data && !error),
    error
  }
}
