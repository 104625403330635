import Button from '../../Button'
import { cn } from '../../../helpers'
import OverlayPortal from '../../OverlayPortal'
import { InsertDataType, StatusType } from '../../../interfaces'

export function OrderInsertsError({
  className = '',
  code,
  orderBrandedInserts,
  saveStatus = 'idle',
  onRemove
}: {
  className?: string
  code: string
  orderBrandedInserts?: InsertDataType[]
  saveStatus?: StatusType
  onRemove?: () => void
}) {
  return (
    <div className={cn('border bg-white p-6', className)}>
      <div className="flex items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        {orderBrandedInserts && orderBrandedInserts.length > 0 && onRemove && (
          <div className="ml-auto">
            <Button
              isLoading={saveStatus === 'loading'}
              variant="secondary"
              size="sm"
              theme="danger"
              onClick={onRemove}
            >
              Remove
            </Button>
          </div>
        )}
      </div>

      <div className="mt-6 text-magenta-800">
        {orderBrandedInserts && orderBrandedInserts.length > 0 && (
          <>
            {orderBrandedInserts.length === 1 ? (
              <>{orderBrandedInserts.length} insert</>
            ) : (
              <>{orderBrandedInserts.length} inserts</>
            )}{' '}
            assigned to this order.{' '}
          </>
        )}
        An error occurred while loading inserts (code {code})
      </div>

      {saveStatus === 'loading' && <OverlayPortal />}
    </div>
  )
}
