import {
  BrandingDataInterface,
  BrandingDetailsType,
  MerchantBrandingType,
  useMerchantService
} from '../../../../../hooks'
import { formatCost } from '../../../../../helpers'
import { EditInsertSet } from './EditInsertSet.component'
import { InsertSetType } from '../../../../../interfaces'
import { AddNewInsertSet } from './AddNewInsertSet.component'
import { DeleteInsertSet } from './DeleteInsertSet.component'

export function BrandedInsertsSetting({
  brandingDetails,
  merchantBranding
}: {
  brandingDetails: BrandingDataInterface
  merchantBranding: MerchantBrandingType
}) {
  return (
    <div>
      {merchantBranding.insertSets.length > 0 && (
        <ul className="mb-16 flex flex-col gap-16">
          {merchantBranding.insertSets.map((insertSet) => (
            <InsertSet
              key={insertSet.id}
              brandingDetails={brandingDetails}
              merchantBranding={merchantBranding}
              insertSet={insertSet}
            />
          ))}
        </ul>
      )}

      <AddNewInsertSet brandingDetails={brandingDetails} merchantBranding={merchantBranding} />
    </div>
  )
}

function InsertSet({
  brandingDetails,
  insertSet,
  merchantBranding
}: {
  brandingDetails: BrandingDataInterface
  insertSet: InsertSetType
  merchantBranding: MerchantBrandingType
}) {
  const { merchantDetails } = useMerchantService()

  const brandingDetailsForInsertSet: BrandingDetailsType[] = []

  insertSet.inserts.forEach((insert) => {
    const brandingDetailForInsert = brandingDetails.branding.find((brandingDetail) => brandingDetail.id === insert.id)
    if (!brandingDetailForInsert) {
      return
    }
    brandingDetailsForInsertSet.push(brandingDetailForInsert)
  })

  const insertSetCost = {
    amount: brandingDetailsForInsertSet.reduce(
      (amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount,
      0
    ),
    currency: brandingDetailsForInsertSet[0]?.cost?.currency
  }

  const formattedInsertSetCost = formatCost({
    amount: insertSetCost.amount.toString(),
    currencyCode: insertSetCost.currency
  })

  const salesChannelNamesForInsertSet =
    merchantDetails && insertSet.salesChannels.length > 0
      ? insertSet.salesChannels
          .map((insertSetSalesChannel) => {
            const salesChannelData = merchantDetails.salesChannels.find(
              (salesChannel) => salesChannel.id === insertSetSalesChannel.id
            )

            if (!salesChannelData) {
              return undefined
            }

            return salesChannelData.description
          })
          .filter(Boolean)
      : []

  return (
    <li className="flex flex-col text-black">
      <div className="text-lg font-medium" style={{ wordBreak: 'break-word' }}>
        {insertSet.name} {formattedInsertSetCost && <span className="font-normal">({formattedInsertSetCost})</span>}
      </div>

      <div className="mt-2">
        {brandingDetailsForInsertSet.length > 0 ? (
          <span>{brandingDetailsForInsertSet.map((brandingDetail) => brandingDetail.name).join(', ')}</span>
        ) : (
          <span>No inserts in this set</span>
        )}
      </div>
      {salesChannelNamesForInsertSet.length > 0 && (
        <div className="text-sm text-gray-600">Default for: {salesChannelNamesForInsertSet.join(', ')}</div>
      )}

      <div className="mt-4 flex gap-3">
        <EditInsertSet
          brandingDetails={brandingDetails}
          merchantBranding={merchantBranding}
          selectedInsertSet={insertSet}
        />

        <DeleteInsertSet merchantBranding={merchantBranding} selectedInsertSet={insertSet} />
      </div>
    </li>
  )
}
