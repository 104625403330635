import { useSWRConfig } from 'swr'
import { twMerge } from 'tailwind-merge'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Popover from '@radix-ui/react-popover'
import { useState, useRef, ReactNode } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { CogIcon, ArrowRightStartOnRectangleIcon, UserIcon } from '@heroicons/react/24/outline'

import InlineMenu from '../../InlineMenu'
import { cn, isProUser } from '../../../helpers'
import { logOut } from '../../../../actions/auth'
import { useSplitToggle, useUser } from '../../../hooks'
import { SubscriptionTierType } from '../../../interfaces'
import { PORTAL_ID } from '../../../constants/portalId.const'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'

type AccountNavItemValueType = 'settings' | 'logout'

const ACCOUNT_NAV_ITEM_OPTIONS: Array<{ content: ReactNode; searchLabel: string; value: AccountNavItemValueType }> = [
  {
    content: (
      <span className="flex items-center pr-10">
        <CogIcon className="h-12 w-12 pr-4 text-gray-600" aria-hidden="true" />
        Settings
      </span>
    ),
    searchLabel: 'Settings',
    value: 'settings'
  },
  {
    content: (
      <span className="flex items-center pr-10">
        <ArrowRightStartOnRectangleIcon className="h-12 w-12 pr-4 text-gray-600" aria-hidden="true" />
        Log out
      </span>
    ),
    searchLabel: 'Logout',
    value: 'logout'
  }
]

export function AccountNavItem({ className = '' }: { className?: string }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { cache } = useSWRConfig()
  const { user } = useUser()
  const { splitIsOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_PRODIGI_PRO })

  const parentRef = useRef<HTMLDivElement | null>(null)
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isAnimatingContent, setIsAnimatingContent] = useState(false)

  function handleLogout() {
    // SWR v1.3.0 supports this as per the docs but is not typed
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cache as any)?.clear?.()
    // TODO: v2: Remove v1 logout when updated
    dispatch(logOut())
  }

  function updateSelected(value: AccountNavItemValueType) {
    if (value === 'settings') {
      const settingsPath =
        process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX ? ROUTE_PATHS.SETTINGS.API : ROUTE_PATHS.SETTINGS.ACCOUNT
      history.push(settingsPath)
    } else if (value === 'logout') {
      handleLogout()
    }
  }

  return (
    <div className="h-full w-full text-input sm:text-base" ref={parentRef}>
      <Popover.Root
        onOpenChange={(open) => {
          setIsOpen(open)
        }}
      >
        <Popover.Trigger
          className={twMerge(
            'relative w-full cursor-pointer truncate rounded-lg border border-none border-gray-200 bg-white px-6 py-6 text-left text-lg outline-offset-1 outline-purple-500 sm:text-base',
            className
          )}
          ref={triggerRef}
        >
          <UserIcon className="h-9 w-9" />
          <span className="sr-only">Account options</span>
        </Popover.Trigger>

        <AnimatePresence>
          {isOpen && (
            <Popover.Portal container={document.getElementById(PORTAL_ID)} forceMount key="Account options">
              <Popover.Content align="end" asChild style={{ perspective: '2000px' }}>
                <motion.div
                  animate="open"
                  className={`mt-1 max-h-96 w-[240px] overflow-auto rounded-lg border border-gray-200 bg-white shadow-lg radix-side-bottom:origin-[center_-45%] radix-side-top:origin-[center_45%]`}
                  exit="close"
                  initial="close"
                  transition={{ duration: 0.15 }}
                  variants={{ close: { opacity: 0, rotateX: '-15deg' }, open: { opacity: 1, rotateX: '0deg' } }}
                  onAnimationComplete={() => {
                    setIsAnimatingContent(false)
                  }}
                >
                  {user && (
                    <div className="flex flex-col bg-purple-100 px-6 py-4">
                      <span className="truncate font-medium">{user.fullName}</span>
                      {splitIsOn && (
                        <span
                          className={cn(
                            'text-xs font-bold uppercase',
                            getSubscriptionTierThemeColor(user.merchantSubscriptionSettings.subscriptionTier)
                          )}
                        >
                          {isProUser(user.merchantSubscriptionSettings.subscriptionTier)
                            ? 'Pro'
                            : user.merchantSubscriptionSettings.subscriptionTier}
                        </span>
                      )}
                      {Boolean(user.company.name) && (
                        <span className="mt-2 truncate text-gray-600">{user.company.name}</span>
                      )}
                    </div>
                  )}

                  <InlineMenu
                    ariaLabel="Account options"
                    // Prevents unwanted clicks on options while animating
                    className={`${isAnimatingContent ? 'pointer-events-none' : ''}`}
                    listItemClassName="border-t border-grey-200 pl-6"
                    options={ACCOUNT_NAV_ITEM_OPTIONS}
                    selected={null}
                    onChange={() => {
                      setIsAnimatingContent(true)
                    }}
                    updateSelected={(selected) => {
                      updateSelected(selected as AccountNavItemValueType)
                      triggerRef.current?.click()
                    }}
                  />
                </motion.div>
              </Popover.Content>
            </Popover.Portal>
          )}
        </AnimatePresence>
      </Popover.Root>
    </div>
  )
}

function getSubscriptionTierThemeColor(subscriptionTier: SubscriptionTierType) {
  switch (subscriptionTier) {
    case 'Core':
      return 'text-purple-600'
    case 'Enterprise':
      return 'text-cyan-500'
    case 'ProMonthlyBilling':
    case 'ProYearlyBilling':
      return 'text-status-orange'
    default:
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled subscription tier ${subscriptionTier}. Add a new case to fix this.`)
      }
      return 'text-gray-600'
  }
}
