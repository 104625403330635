import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

import {
  BrandedInsertsOrderModal,
  OrderInsertsContent,
  OrderInsertsError,
  OrderInsertsLoading,
  OrderInsertsNoContent
} from '../../../../v2/components/BrandedInsertsOrderModal'
import Button from '../../../../v2/components/Button'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { getCsvOrderBranding } from '../../../../selectors/csvUpload'
import { requestImageLibraryThumbnailGeneration } from '../../../../v2/helpers'
import { createErrorToast, createToast } from '../../../../v2/components/Toast'
import { updateCsvInserts } from '../../../../actions/csvUpload/updateCsvInserts'
import { fetchPricingAndShippingForCsvOrder } from '../../../../actions/csvUpload'
import { FetchErrorInterface, InsertDataType, StatusType } from '../../../../v2/interfaces'
import { useBrandingDetails, useMerchantBranding, useSplitToggle } from '../../../../v2/hooks'

type CsvBrandedInsertsType = {
  orderId: string
}

const CSV_INSERTS_TOAST_ID = 'prodigiCsvInsertsToast'

export function CsvBrandedInserts(props: CsvBrandedInsertsType) {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  if (!isBrandingSettingsOn) {
    return null
  }

  return <CsvBrandedInsertsData {...props} />
}

function CsvBrandedInsertsData({ orderId }: CsvBrandedInsertsType) {
  const dispatch = useDispatch()
  const { brandingDetails, brandingDetailsFetchError, isLoadingBrandingDetails } = useBrandingDetails()
  const { merchantBrandingResponse, merchantBrandingFetchError, isLoadingMerchantBranding } = useMerchantBranding()

  const [saveStatus, setSaveStatus] = useState<StatusType>('idle')
  const [isOrderBrandedInsertsModalOpen, setIsOrderBrandedInsertsModalOpen] = useState(false)

  const csvOrderBrandedInserts: InsertDataType[] | null = useSelector((state) => getCsvOrderBranding(state, orderId))
  const orderBrandedInserts = csvOrderBrandedInserts ?? []

  function handleRemoveAll() {
    dispatch(updateCsvInserts({ orderId, inserts: [] }))
    dispatch(fetchPricingAndShippingForCsvOrder(orderId))
  }

  if (brandingDetailsFetchError) {
    return (
      <OrderInsertsError
        code={`BDFE-${brandingDetailsFetchError.status ?? 0}`}
        orderBrandedInserts={orderBrandedInserts}
        onRemove={handleRemoveAll}
      />
    )
  }

  if (merchantBrandingFetchError) {
    return (
      <OrderInsertsError
        code={`MBFE-${merchantBrandingFetchError.status ?? 0}`}
        orderBrandedInserts={orderBrandedInserts}
        onRemove={handleRemoveAll}
      />
    )
  }

  if (isLoadingBrandingDetails || !brandingDetails || isLoadingMerchantBranding || !merchantBrandingResponse) {
    return <OrderInsertsLoading />
  }

  const merchantBranding = merchantBrandingResponse.data
  const hasInserts = orderBrandedInserts.length > 0

  async function handleSelectInserts(newBrandedInserts: InsertDataType[]) {
    toast.dismiss(CSV_INSERTS_TOAST_ID)
    setSaveStatus('loading')

    try {
      const brandedInsertsImageRequests = newBrandedInserts
        .filter((brandedInsert) => Boolean(brandedInsert.imageLibraryId))
        .map((brandedInsert) => requestImageLibraryThumbnailGeneration(brandedInsert.imageLibraryId ?? ''))

      const brandedInsertsImageGenerationResponses = await Promise.all(brandedInsertsImageRequests)

      const brandedInsertsForOrderWithUpdatedUrls: InsertDataType[] = newBrandedInserts.map((brandedInsert) => {
        const imageData = brandedInsertsImageGenerationResponses.find(
          (imageResponse) => imageResponse.public_id === brandedInsert.imageLibraryId
        )

        return {
          ...brandedInsert,
          imageLibraryId: imageData?.public_id ?? brandedInsert.imageLibraryId,
          imageUrl: imageData?.url ?? brandedInsert.imageUrl
        }
      })

      const newBrandedInsertsWithImageUrls = brandedInsertsForOrderWithUpdatedUrls.filter((insert) =>
        Boolean(insert.imageUrl)
      )

      dispatch(updateCsvInserts({ orderId, inserts: newBrandedInsertsWithImageUrls }))
      dispatch(fetchPricingAndShippingForCsvOrder(orderId))
      setSaveStatus('success')
      handleOpenChange(false)
      createToast({ type: 'success', heading: 'Inserts updated' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string; imageLibraryId?: string }>
      const errorCode = `${errorResponse?.status ?? 0} ${errorResponse?.responseBodyJson?.imageLibraryId ?? 0}`
      createErrorToast({
        errorCode,
        errorMessage: errorResponse?.responseBodyJson?.detail ?? errorResponse?.message,
        heading: 'Failed to update branded inserts',
        id: CSV_INSERTS_TOAST_ID
      })
      setSaveStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setSaveStatus('idle')
      toast.dismiss(CSV_INSERTS_TOAST_ID)
    }
    setIsOrderBrandedInsertsModalOpen(isOpen)
  }

  return (
    <div className="border bg-white p-6">
      <div className="flex flex-wrap items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        <div className="ml-auto">
          <Button variant="secondary" size="sm" onClick={() => handleOpenChange(true)}>
            {hasInserts ? 'View & edit' : 'Add inserts'}
          </Button>
        </div>
      </div>

      {hasInserts ? (
        <OrderInsertsContent brandingDetails={brandingDetails} orderBrandedInserts={orderBrandedInserts} />
      ) : (
        <OrderInsertsNoContent />
      )}

      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={orderBrandedInserts}
        insertSets={merchantBranding.insertSets}
        open={isOrderBrandedInsertsModalOpen}
        saveStatus={saveStatus}
        setOpen={handleOpenChange}
        onSave={handleSelectInserts}
        onClose={() => handleOpenChange(false)}
      />
    </div>
  )
}
