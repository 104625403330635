import { cn, formatCost } from '../../../helpers'
import { InsertDataType } from '../../../interfaces'
import { BrandingDataInterface, BrandingDetailsType } from '../../../hooks'

export function OrderInsertsContent({
  className = '',
  brandingDetails,
  orderBrandedInserts
}: {
  className?: string
  brandingDetails: BrandingDataInterface
  orderBrandedInserts: InsertDataType[]
}) {
  const brandingDetailsForOrderInserts: BrandingDetailsType[] = []

  orderBrandedInserts.forEach((insert) => {
    const brandingDetailForInsert = brandingDetails.branding.find((brandingDetail) => brandingDetail.id === insert.id)
    if (!brandingDetailForInsert) {
      return
    }
    brandingDetailsForOrderInserts.push(brandingDetailForInsert)
  })

  const insertSetCost = {
    amount: brandingDetailsForOrderInserts.reduce(
      (amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount,
      0
    ),
    currency: brandingDetailsForOrderInserts[0]?.cost?.currency
  }

  const formattedInsertSetCost = formatCost({
    amount: insertSetCost.amount.toString(),
    currencyCode: insertSetCost.currency
  })

  return (
    <div className={cn('mt-6 text-black', className)}>
      <div>
        {orderBrandedInserts.length === 1
          ? `${orderBrandedInserts.length} insert`
          : `${orderBrandedInserts.length} inserts`}
        {formattedInsertSetCost ? `, ${formattedInsertSetCost}` : null}
      </div>
      <div className="text-gray-700">
        {brandingDetailsForOrderInserts.length > 0 ? (
          <span>{brandingDetailsForOrderInserts.map((brandingDetail) => brandingDetail.name).join(', ')}</span>
        ) : (
          <span>Branding details not found</span>
        )}
      </div>
    </div>
  )
}
