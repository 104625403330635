import { Link } from 'react-router-dom'

import { useUser } from '../../../hooks'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { calculatePlanSaving, formatBillingCost, getProBillingOptions } from '../../BillingSubscriptionPlans'

export function ProdigiProPanel() {
  const { user } = useUser()

  if (!user || process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX) {
    return null
  }

  if (user.merchantSubscriptionSettings.subscriptionTier === 'Enterprise') {
    return null
  }

  const proPlanBillingOptions = getProBillingOptions(user.merchantSubscriptionSettings?.subscriptionTierPricing)
  const saving = calculatePlanSaving(proPlanBillingOptions)

  if (user.merchantSubscriptionSettings.subscriptionTier === 'Core') {
    return (
      <div className="w-full">
        <div className="container mx-auto">
          <div className="flex flex-col justify-between bg-white p-12 shadow-md lg:mt-4">
            <h2 className="text-black">Save money and scale faster with Prodigi Pro </h2>
            <p className="mt-8 text-gray-600">
              Get instant access to exclusive benefits including 10% off all products, 75% off branded inserts and 24/7
              customer support.
            </p>
            <p className="mt-4 text-gray-600">
              All for just {formatBillingCost(proPlanBillingOptions.ProYearlyBilling)}/year when you choose annual
              billing (save {saving}/year)
            </p>
            <Link
              className="btn btn-secondary mt-12 w-fit"
              to={ROUTE_PATHS.SETTINGS.BILLING}
              onClick={() => window.analytics.track('Clicked - subscribe to Pro')}
            >
              Subscribe to Pro
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="container mx-auto">
        <div className="flex flex-col justify-between bg-white p-12 shadow-md lg:mt-4">
          <h2 className="text-black">Maximise your Prodigi Pro benefits</h2>
          <p className="mt-8 text-gray-600">
            From dedicated account management for personalised support to early access to new products and features –
            make the most of your Pro subscription.
          </p>
          <Link
            className="btn btn-secondary mt-12 w-fit"
            to={ROUTE_PATHS.SETTINGS.BILLING}
            onClick={() => window.analytics.track('Clicked - subscribe to Pro')}
          >
            See what&apos;s possible
          </Link>
        </div>
      </div>
    </div>
  )
}
