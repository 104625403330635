import { useParams } from 'react-router-dom'

import { InsertDataType } from '../../../interfaces'
import { BrandedInsertIdType, useBrandingDetails, useOrderDetail } from '../../../hooks'
import { OrderInsertsContent, OrderInsertsError, OrderInsertsLoading } from '../../BrandedInsertsOrderModal'

export function OrderDetailBrandedInserts() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const { brandingDetails, brandingDetailsFetchError, isLoadingBrandingDetails } = useBrandingDetails()
  const orderDetail = orderDetailsResponse?.data

  const orderBrandedInserts: InsertDataType[] = Object.entries(orderDetail?.order.branding.inserts ?? {}).map(
    ([insertId, { url }]) => {
      return {
        id: insertId as BrandedInsertIdType,
        imageUrl: url
      }
    }
  )

  if (brandingDetailsFetchError) {
    return <OrderInsertsError code={`BDFE-${brandingDetailsFetchError.status ?? 0}`} />
  }

  if (isLoadingBrandingDetails || !brandingDetails) {
    return <OrderInsertsLoading />
  }

  const hasInserts = orderBrandedInserts.length > 0

  return (
    <div className="border bg-white p-6">
      <h2 className="mt-0 text-lg">Inserts</h2>

      {hasInserts ? (
        <OrderInsertsContent brandingDetails={brandingDetails} orderBrandedInserts={orderBrandedInserts} />
      ) : (
        <div className="mt-6">No inserts</div>
      )}
    </div>
  )
}
