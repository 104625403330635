import { useState } from 'react'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import { StatusEnum } from '../../../enums'
import ConfirmationModal from '../../ConfirmationModal'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../Settings/constants'

export function CancelProSubscriptionModal({
  isModalOpen,
  setIsModalOpen
}: {
  isModalOpen: boolean
  setIsModalOpen: (open: boolean) => void
}) {
  const [updateStatus, setUpdateStatus] = useState(StatusEnum.Idle)

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    }
    setIsModalOpen(isOpen)
  }

  function switchToCore() {
    console.log('Switch to core')
    handleOpenChange(false)
    setUpdateStatus(StatusEnum.Success)
    // TODO: add request to BE
    // handle failure
    // on success: dispatch(refreshAccessToken())
  }

  const isLoading = updateStatus === StatusEnum.Loading

  // DASHBOARD_PRODIGI_PRO - replace with real data
  const nextPaymentDate = new Date('2024-12-12T14:23:12.0000000Z')

  return (
    <ConfirmationModal
      className="relative max-w-[95vw] bg-white sm:min-w-[600px]"
      contentClassName="max-h-60vh overflow-auto pb-4 text-base max-w-4xl"
      continueButton={{
        variant: 'primary',
        theme: 'danger',
        text: 'Yes, cancel my Pro subscription'
      }}
      closeButton={{ variant: 'tertiary', theme: 'greyscale', text: 'No, do not cancel' }}
      isLoading={isLoading}
      open={isModalOpen}
      title="Cancel your Pro subscription"
      setOpen={handleOpenChange}
      onCancel={() => handleOpenChange(false)}
      onContinue={switchToCore}
    >
      <p>
        Your Prodigi Pro subscription is active until{' '}
        <span className="font-medium">{format(nextPaymentDate, 'dd MMMM YYY')}</span>. You&apos;ll continue to enjoy all
        Pro benefits until then.
      </p>

      <p className="mt-8">
        After {format(nextPaymentDate, 'dd MMMM YYY')}, your account will revert to free status with the following
        standard features:
      </p>

      <ul className="mt-4 list-inside list-disc">
        <li className="mt-2">
          <span className="italic line-through">10% off all product prices</span> Walkup pricing
        </li>
        <li className="mt-2">
          <span className="italic line-through">75% discount on all branded packaging inserts</span> Free delivery notes
          (where supported)
        </li>
        <li className="mt-2">
          <span className="italic line-through">50% savings on all sample packs</span> Not included
        </li>
        <li className="mt-2">
          <span className="italic line-through">Free first sample order</span> 50% off your first sample order only
        </li>
        <li className="mt-2">
          <span className="italic line-through">High-resolution mockups</span> Standard product mockups
        </li>
        <li className="mt-2">
          <span className="italic line-through">Dedicated account manager for personalised support</span> Not included
        </li>
        <li className="mt-2">
          <span className="italic line-through">Early access to new products & features</span> Not included
        </li>
        <li className="mt-2">
          <span className="italic line-through">24/7 customer support with response time of {'<'} 12hrs</span> Email &
          live chat support during business hours only
        </li>
        <li className="mt-2">
          <span className="italic line-through">Access to planned integrations with Amazon, eBay & more</span> Access to
          select integrations only
        </li>
      </ul>
    </ConfirmationModal>
  )
}
