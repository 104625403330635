import { CheckCircleIcon } from '@heroicons/react/20/solid'

import { cn } from '../../../helpers'
import { formatBillingCost } from '../helpers'
import { SALES_EMAIL } from '../../SalesLink/constants'
import { BillingPlanType, SelectedPlan } from '../interfaces'

const features = [
  'Free packing slips',
  '50% off first sample order',
  'Free product mockups',
  '10Gb image library storage',
  'Email & live chat support'
]

export function CoreSubscriptionPanel({
  currency,
  userBillingPlan,
  userHasToAddBillingDetails,
  setIsCancelProSubscriptionModalOpen
}: {
  currency: string
  userBillingPlan: SelectedPlan
  userHasToAddBillingDetails: boolean
  setIsCancelProSubscriptionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const isUsersCurrentSubscription = userBillingPlan.id === 'Core'

  return (
    <div
      className={cn(
        'flex h-full w-full flex-col justify-between gap-y-4 rounded-2xl border-2 border-gray-200 bg-white p-8 transition-colors duration-300',
        isUsersCurrentSubscription && 'border-purple-700 bg-purple-100/75'
      )}
    >
      <div>
        <div className="flex justify-between">
          <h3 className="m-0 text-xl font-medium lg:text-2xl">Core</h3>
        </div>

        <p className="mt-1 text-gray-600">For new &amp; small businesses</p>

        <ul className="my-8 max-w-fit list-inside list-disc">
          {features.map((feature) => (
            <li className="mt-2" key={feature}>
              {feature}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <p className="text-4xl font-medium text-purple-600">
            {formatBillingCost({ cost: 0, currency })}/mo <span className="text-xl">(free forever)</span>
          </p>
        </div>

        <CorePlanButton
          userBillingPlan={userBillingPlan}
          userHasToAddBillingDetails={userHasToAddBillingDetails}
          setIsModalOpen={() => setIsCancelProSubscriptionModalOpen(true)}
        />

        {!isUsersCurrentSubscription && <p className="mt-4 text-gray-600">This will cancel your current plan</p>}
      </div>
    </div>
  )
}

function CorePlanButton({
  userBillingPlan,
  userHasToAddBillingDetails,
  setIsModalOpen
}: {
  userBillingPlan: SelectedPlan
  userHasToAddBillingDetails: boolean
  setIsModalOpen: (open: boolean) => void
}) {
  const isUsersCurrentSubscription = userBillingPlan.id === 'Core'
  const buttonText = getButtonText({ currentBillingPlan: userBillingPlan.id })

  if (userBillingPlan.subscriptionTier === 'Enterprise') {
    return (
      <a
        className={cn(
          'mt-4 w-fit transition-colors duration-300',
          isUsersCurrentSubscription
            ? 'pointer-events-none relative flex flex-row-reverse items-center gap-2 border-transparent bg-transparent text-purple-600 hover:border-transparent hover:text-purple-600 md:flex-row md:py-[0.9rem]'
            : 'btn btn-secondary'
        )}
        href={`mailto:${SALES_EMAIL}`}
        rel="noreferrer"
        target="_blank"
      >
        Contact sales to switch to Core
      </a>
    )
  }

  return (
    <button
      className={cn(
        'mt-4 w-fit transition-colors duration-300',
        isUsersCurrentSubscription
          ? 'pointer-events-none relative flex flex-row-reverse items-center gap-2 border-transparent bg-transparent text-purple-600 hover:border-transparent hover:text-purple-600 md:flex-row md:py-[0.9rem]'
          : 'btn btn-secondary'
      )}
      disabled={userHasToAddBillingDetails || isUsersCurrentSubscription}
      onClick={() => setIsModalOpen(true)}
    >
      {isUsersCurrentSubscription ? (
        <>
          <CheckCircleIcon className="h-10 w-10" />
          {buttonText}
        </>
      ) : (
        buttonText
      )}
    </button>
  )
}

function getButtonText({ currentBillingPlan }: { currentBillingPlan: BillingPlanType }) {
  if (currentBillingPlan === 'Core') {
    return `You currently have a free account`
  }

  return 'Subscribe to a free account'
}
