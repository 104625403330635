import { EXTERNAL_URLS } from '../../../../../../constants'

export function BillingTerms() {
  return (
    <div className="mt-8">
      <p className="mb-2">
        By saving your payment details, you agree to be charged for your orders monthly, weekly or per order, as
        specified in the &quot;Invoice Frequency&quot; field below.
      </p>
      <p className="mb-2">
        The payment amount will be a sum of prices for your orders in the billing cycle, or in the case of per order
        billing, will be clearly defined on the order summary page.
      </p>
      <p>
        You can find additional information regarding payments in our{' '}
        <a
          className="text-purple-500"
          rel="noopener noreferrer"
          target="_blank"
          href={EXTERNAL_URLS.PRODIGI.TERMS_OF_USE.URL}
        >
          terms &amp; conditions.
        </a>
      </p>
    </div>
  )
}
