import { SubscriptionTierType } from '../../../interfaces'

export function formatPricing(
  pricing: Record<string, { cost: number; currency: string }>
): Record<SubscriptionTierType, { cost: number; currency: string }> {
  return Object.entries(pricing).reduce(
    (proPricingAcc: Record<string, { cost: number; currency: string }>, [key, billing]) => {
      if (['promonthlybilling', 'proyearlybilling', 'enterprise', 'core'].includes(key.toLowerCase())) {
        const formattedKey = key[0].toUpperCase() + key.slice(1)
        proPricingAcc[formattedKey] = billing
      }

      return proPricingAcc
    },
    {}
  )
}
