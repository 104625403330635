import { ProBillingOption } from '../interfaces'
import { formatPricing } from './formatPricing.helper'

export function getProBillingOptions(
  pricing: Record<string, { cost: number; currency: string }>
): Record<ProBillingOption, { cost: number; currency: string }> {
  const userPricing = formatPricing(pricing)

  return {
    ProYearlyBilling: userPricing['ProYearlyBilling'],
    ProMonthlyBilling: userPricing['ProMonthlyBilling']
  }
}
