// @flow
import type {
  Thunk,
  FormattedCsvRow,
  FormattedOrderItem,
  GetStateFunc,
  DispatchFunc,
  QuoteRequestItem,
  V4ProductDetails,
  Dictionary
} from '../../types'
import { getCsvOrderWithItems } from '../../selectors/csvUpload'
import { getRequiredPrintAreaNames } from '../../helpers/v4ProductDetails'
import { mapV3AttributesToV4 } from '../../helpers/attributes'
import { getV4ProductDetailsBySkus } from '../../selectors/catalogue'
import { values } from '../../helpers/dictionary'
import { getQuotes } from '../quotes'
import { getAllArtworks } from '../../selectors/images/images'
import type { Artwork } from '../../types/images'
import { buildImageId } from '../images'
import { getCsvOrderBranding } from '../../selectors/csvUpload/csvUpload'

export function fetchPricingAndShippingForCsvOrder(orderId: string): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const [order, orderItems] = getCsvOrderWithItems(getState(), orderId)

    const hasEnoughDetailsToCreateQuote = Boolean(
      order && order.itemIds.length > 0 && order.customer && order.customer.countryCode
    )

    if (!hasEnoughDetailsToCreateQuote) {
      return null
    }

    return dispatch(getCsvOrderQuote(order, orderItems))
  }
}

function getCsvOrderQuote(order: FormattedCsvRow, orderItems: Dictionary<FormattedOrderItem>): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const allSkus = values(orderItems)
      .map((item) => item.sku)
      .filter(Boolean)
    const v4ProductDetails = getV4ProductDetailsBySkus(getState(), allSkus)
    const artworks = getAllArtworks(getState())
    const quoteItems = mapCsvOrderItemsToQuoteRequestItems(orderItems, v4ProductDetails, artworks)
    const brandedInserts = getCsvOrderBranding(getState(), order.id) ?? []

    if (quoteItems.length === 0) {
      return null
    }

    return dispatch(getQuotes(order.id, quoteItems, order.customer.countryCode, brandedInserts))
  }
}

export function mapCsvOrderItemsToQuoteRequestItems(
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<V4ProductDetails>,
  artworks: Dictionary<Artwork>
): QuoteRequestItem[] {
  return values(orderItems)
    .map((orderItem) =>
      orderItem.sku && productDetails[orderItem.sku]
        ? mapCsvOrderItemToQuoteRequestItem(orderItem, productDetails[orderItem.sku], artworks)
        : null
    )
    .filter(Boolean)
}

export function mapCsvOrderItemToQuoteRequestItem(
  orderItem: FormattedOrderItem,
  productDetails: V4ProductDetails,
  artworks: Dictionary<Artwork>
): QuoteRequestItem {
  const requiredPrintAreaNames = getRequiredPrintAreaNames(productDetails)

  return {
    sku: orderItem.sku,
    copies: orderItem.quantity,
    attributes: mapV3AttributesToV4(orderItem.selectedAttributes, productDetails),
    assets: requiredPrintAreaNames.map((requiredPrintArea) => {
      const imageId = buildImageId(orderItem.id, requiredPrintArea)
      const artworkForPrintArea = artworks[imageId]

      return {
        printArea: requiredPrintArea,
        pageCount:
          artworkForPrintArea?.pageCount && artworkForPrintArea.pageCount > 0
            ? artworkForPrintArea.pageCount
            : undefined
      }
    })
  }
}
