// @flow
import type {
  DispatchFunc,
  GetStateFunc,
  MultiAssetBasketItem,
  Dictionary,
  V4ProductDetails,
  QuoteRequestItem,
  Quote,
  ThunkAsync
} from '../../types'
import { selectBasketItems, selectDeliveryCountry } from '../../selectors/manualOrderForm'
import { getV4ProductDetailsBySkus } from '../../selectors/catalogue'
import { getQuotes, GET_QUOTES_SUCCESS, GET_MOF_QUOTES_REQUEST_ID } from '../quotes'
import { mapV3AttributesToV4 } from '../../helpers/attributes'
import { selectMofShippingMethod } from './selectMofShippingMethod'
import type { InsertDataType } from '../../types/branding'

export type AdditionalQuoteData = {|
  orderBrandedInserts?: InsertDataType[]
|}

export function getMofBasketQuotes(additionalQuoteData: AdditionalQuoteData): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const basketItems = selectBasketItems(getState())

    if (basketItems.length === 0) {
      return
    }

    const allSkus = basketItems.map((item) => item.sku)
    const v4ProductDetails = getV4ProductDetailsBySkus(getState(), allSkus)
    const quoteItems = mapBasketItemsToQuoteRequestItems(basketItems, v4ProductDetails)
    const deliveryCountry = selectDeliveryCountry(getState())

    const actionResult = await dispatch(
      getQuotes(GET_MOF_QUOTES_REQUEST_ID, quoteItems, deliveryCountry, additionalQuoteData.orderBrandedInserts)
    )

    if (actionResult.type === GET_QUOTES_SUCCESS) {
      dispatch(selectDefaultShippingMethod(actionResult.payload.quotes))
    }
  }
}

function selectDefaultShippingMethod(quotes: Quote[]): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const standardMethod = quotes.find((quote) => quote.shipmentMethod.toLowerCase().startsWith('standard'))

    const standardShippingMethodName = standardMethod ? standardMethod.shipmentMethod : null

    const firstAvailableShippingMethodName = quotes.length > 0 ? quotes[0].shipmentMethod : null

    const defaultShippingMethod = standardShippingMethodName ?? firstAvailableShippingMethodName

    dispatch(selectMofShippingMethod(defaultShippingMethod))
  }
}

function mapBasketItemsToQuoteRequestItems(
  basketItems: MultiAssetBasketItem[],
  productDetails: Dictionary<V4ProductDetails>
): QuoteRequestItem[] {
  return basketItems.map((item) => mapMultiAssetBasketItemToQuoteRequestItem(item, productDetails[item.sku]))
}

function mapMultiAssetBasketItemToQuoteRequestItem(
  basketItem: MultiAssetBasketItem,
  productDetails: V4ProductDetails
): QuoteRequestItem {
  if (!basketItem.printAreas) {
    throw Error(`Print areas cannot be null, basketItem ${basketItem.id} - ${basketItem.sku}`)
  }
  const basketItemPrintAreas = basketItem.printAreas
  const printAreasWithArtworks = Object.keys(basketItemPrintAreas).filter((printAreaName) =>
    Boolean(basketItemPrintAreas[printAreaName].artwork)
  )

  return {
    sku: basketItem.sku,
    copies: basketItem.quantity,
    attributes: mapV3AttributesToV4(
      basketItem.selectedAttributes,
      productDetails,
      basketItem?.costedAttributeKeys?.value ?? []
    ),
    assets: printAreasWithArtworks.map((printAreaName) => {
      const artworkData = basketItemPrintAreas?.[printAreaName]?.artwork
      return {
        printArea: printAreaName,
        pageCount: artworkData?.pageCount && artworkData.pageCount > 0 ? artworkData.pageCount : undefined
      }
    })
  }
}
