import { ChatBubbleLeftRightIcon, ArrowTopRightOnSquareIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

import { NavGroupTitle } from './NavGroupTitle.component'
import { NavGroupListContainer } from './NavGroupListContainer.component'

export function NavGroupHelp({ className }: { className?: string; onOpenChat?: () => void }) {
  return (
    <div className={className}>
      <NavGroupTitle>Help</NavGroupTitle>

      <NavGroupListContainer>
        <li>
          <a
            className="side-nav-item text-inherit w-full"
            href="https://support.prodigi.com/hc/en-us/requests/new"
            rel="noreferrer"
            target="_blank"
          >
            <ChatBubbleLeftRightIcon className="h-7 w-7" />
            Support
            <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-purple-100" />
          </a>
        </li>

        <li>
          <a
            className="side-nav-item text-inherit w-full"
            href="https://www.prodigi.com/faq/"
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              window.analytics.track('Clicked: FAQs in menu')
            }}
          >
            <QuestionMarkCircleIcon className="h-7 w-7" />
            FAQs
            <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-purple-100" />
          </a>
        </li>
      </NavGroupListContainer>
    </div>
  )
}
