import { CheckCircleIcon } from '@heroicons/react/20/solid'

import { cn } from '../../../helpers'
import { SelectedPlan } from '../interfaces'
import { SALES_EMAIL } from '../../SalesLink/constants'

const features = [
  'Bespoke volume-based pricing',
  'Free branded packaging inserts',
  'Concierge & bulk-processing services',
  'White-label outsourced customer support',
  'Custom integrations'
]

export function EnterpriseSubscriptionPanel({ userBillingPlan }: { userBillingPlan: SelectedPlan }) {
  const isUsersCurrentSubscription = userBillingPlan.id === 'Enterprise'

  return (
    <div
      className={cn(
        'flex h-full w-full flex-col justify-between gap-y-4 rounded-2xl border-2 border-gray-200 bg-white p-8 transition-colors duration-300',
        isUsersCurrentSubscription && 'border-purple-700 bg-purple-100/75'
      )}
    >
      <div>
        <div className="flex justify-between">
          <h3 className="m-0 text-xl font-medium lg:text-2xl">Enterprise</h3>
        </div>

        <p className="mt-1 text-gray-600">For high-volume sellers aiming for enterprise-level growth</p>

        <ul className="my-8 max-w-fit list-inside list-disc">
          {features.map((feature) => (
            <li className="mt-2" key={feature}>
              {feature}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <p className="text-4xl font-medium text-purple-600">Get in touch</p>
        </div>

        <a
          className={cn(
            'mt-4 w-fit transition-colors duration-300',
            isUsersCurrentSubscription
              ? 'pointer-events-none relative flex flex-row-reverse items-center gap-2 border-transparent bg-transparent text-purple-600 hover:border-transparent hover:text-purple-600 md:flex-row md:py-[0.9rem]'
              : 'btn btn-secondary'
          )}
          href={`mailto:${SALES_EMAIL}`}
          rel="noreferrer"
          target="_blank"
        >
          {isUsersCurrentSubscription ? (
            <>
              <CheckCircleIcon className="h-10 w-10" />
              You&apos;re currently subscribed to Enterprise
            </>
          ) : (
            'Contact sales'
          )}
        </a>
      </div>
    </div>
  )
}
