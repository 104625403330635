// @flow
import type { Dictionary, MappedCsv, Country, CsvFileType } from '../../types'
import { values } from '../../helpers/dictionary'
import shortid from 'shortid'
import { formatCsvRows, formatValue } from '.'
import { IMAGE_SIZING } from '../../data/imageSizing'
import { FIRST_PART_OF_ATTRIBUTE, DESCRIPTION } from '../../data/csvUpload'
import type { FormattedCsvRow, FormattedOrderItem } from '../../types/csvUpload'

// $FlowFixMe: TypeScript
import { isUrl } from 'src/v2/components/OrderDetailShipmentDisplay/helpers'

export function mapCsvRows(data: Dictionary<string>[], countries: Country[], fileType: CsvFileType): MappedCsv {
  const orders = {}
  const orderItems = {}
  let formattedCsvRows = {}
  const productDescriptions = {}

  if (data.length > 0) {
    formattedCsvRows = formatCsvRows(data, fileType)
  }

  formattedCsvRows.forEach((row) => {
    if (row && !values(row).every((item) => item === '')) {
      const orderId = shortid.generate()
      const item: FormattedOrderItem = {
        id: shortid.generate(),
        sku: '',
        selectedAttributes: {},
        unknownPrintAreaImageUrl: '',
        printAreaImageUrls: {},
        transformImageUrls: {},
        selectedPrintArea: null,
        quantity: 1,
        sizing: IMAGE_SIZING.FILL
      }

      const order: FormattedCsvRow = {
        id: orderId,
        merchantOrderId: '',
        isUsSalesTaxCollected: false,
        preferredShippingMethod: '',
        itemIds: [],
        customsValue: 0,
        packingSlip: '',
        branding: [],
        customer: {
          name: '',
          address1: '',
          address2: '',
          town: '',
          county: '',
          countryCode: '',
          postcode: '',
          email: '',
          mobileTelephone: ''
        }
      }

      Object.keys(row).forEach((key) => {
        const value = formatValue(key, row[key], countries)

        if (key === DESCRIPTION) {
          if (value) {
            productDescriptions[item.id] = value
          }
        } else if (Object.keys(order.customer).includes(key)) {
          order.customer[key] = value
        } else if (Object.keys(item).includes(key)) {
          item[key] = value
        } else if (key.includes(FIRST_PART_OF_ATTRIBUTE)) {
          if (value) {
            const attributeKey = key.replace(FIRST_PART_OF_ATTRIBUTE, '')
            item.selectedAttributes[attributeKey] = value.toString()
          }
        } else if (key.startsWith('insert') && typeof value === 'string' && isUrl(value)) {
          assignInserts({ order, key, value })
        } else {
          order[key] = value
        }

        return order
      })

      orderItems[item.id] = item

      orders[orderId] = {
        ...order,
        itemIds: [...order.itemIds, item.id]
      }
    }
  })

  return { orders, orderItems, productDescriptions }
}

type AssignInsertsType = {| order: FormattedCsvRow, key: string, value: string |}

function assignInserts({ order, key, value }: AssignInsertsType) {
  switch (key) {
    case 'insertPostcard': {
      order.branding.push({
        id: 'postcard',
        imageUrl: value
      })
      break
    }
    case 'insertFlyer': {
      order.branding.push({
        id: 'flyer',
        imageUrl: value
      })
      break
    }
    case 'insertStickerExteriorRound': {
      order.branding.push({
        id: 'sticker_exterior_round',
        imageUrl: value
      })
      break
    }
    case 'insertStickerExteriorRectangle': {
      order.branding.push({
        id: 'sticker_exterior_rectangle',
        imageUrl: value
      })
      break
    }
    case 'insertStickerInteriorRound': {
      order.branding.push({
        id: 'sticker_interior_round',
        imageUrl: value
      })
      break
    }
    case 'insertStickerInteriorRectangle': {
      order.branding.push({
        id: 'sticker_interior_rectangle',
        imageUrl: value
      })
      break
    }
    case 'insertPackingSlipColor': {
      order.branding.push({
        id: 'packing_slip_color',
        imageUrl: value
      })
      break
    }
    case 'insertPackingSlipBw': {
      order.branding.push({
        id: 'packing_slip_bw',
        imageUrl: value
      })
      break
    }
  }
}
