import { useSplitToggle } from 'src/v2/hooks'
import { FEATURE_NAMES } from 'src/split-io/feature-names'

import { useUserV1 } from '../../hooks/useUserV1.hook'
import V1LoadingIndicatorFullPage from '../V1LoadingIndicatorFullPage'
import { useMerchantService } from '../../hooks/useMerchantService.hook'
import { DashboardHeader, ProdigiProPanel, RecommendedProducts, QuickLinks } from './components'

export default function Dashboard() {
  const user = useUserV1()
  const { merchantDetails, isLoading: isLoadingMerchantDetails } = useMerchantService()
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })
  const { splitIsOn: isProdigiProSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_PRODIGI_PRO })

  if (isLoadingMerchantDetails) {
    return <V1LoadingIndicatorFullPage />
  }

  const hasPlacedSamplePackOrder = Boolean(merchantDetails?.hasOrderedSamplePack)
  const hasPlacedProductOrder = Boolean(merchantDetails?.hasOrderedProduct)
  const hasPaymentCardSetup = Boolean(user?.billing.hasPaymentCardSetup)

  return (
    <div>
      <DashboardHeader
        hasPaymentCardSetup={hasPaymentCardSetup}
        hasPlacedOrder={hasPlacedProductOrder}
        hasPlacedSamplePackOrder={hasPlacedSamplePackOrder}
      />

      {isProdigiProSplitOn && (
        <section className="mt-4">
          <ProdigiProPanel />
        </section>
      )}

      {!isShopifySplitOn && (
        <section className="mt-4">
          <RecommendedProducts />
        </section>
      )}

      <section>
        <QuickLinks />
      </section>
    </div>
  )
}
